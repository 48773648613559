import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { app } from './config';

const storage = getStorage(app);

interface DownloadOptions {
  filename?: string;
  fallbackUrl?: string;
}

// Cache for successful proxy URLs to speed up subsequent downloads
const proxyCache = new Map<string, string>();

async function triggerDownload(blob: Blob, filename: string) {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = filename;
  document.body.appendChild(link);
  
  try {
    // Reduced delay for faster response
    await new Promise(resolve => setTimeout(resolve, 50));
    link.click();
  } finally {
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
  }
}

function getStoragePath(url: string): string | null {
  try {
    if (!url.includes('firebasestorage.googleapis.com')) return null;
    
    const urlObj = new URL(url);
    const pathMatch = urlObj.pathname.match(/^\/v0\/b\/[^/]+\/o\/(.+)$/);
    if (!pathMatch) return null;
    
    return decodeURIComponent(pathMatch[1].replace(/\%2F/g, '/'));
  } catch {
    return null;
  }
}

async function fetchWithCorsProxy(url: string): Promise<Response> {
  // Check cache first
  const cachedProxy = proxyCache.get(url);
  if (cachedProxy) {
    try {
      const response = await fetch(cachedProxy, {
        headers: { 'Accept': 'image/*' },
        cache: 'no-cache'
      });
      if (response.ok) return response;
    } catch (error) {
      proxyCache.delete(url); // Clear failed cache entry
    }
  }

  // Try direct fetch with optimized headers
  try {
    const response = await fetch(url, {
      headers: {
        'Accept': 'image/*',
        'Cache-Control': 'no-cache'
      },
      mode: 'cors',
      cache: 'no-cache'
    });
    
    if (response.ok) return response;
  } catch (error) {
    console.warn('Direct fetch failed, trying CORS proxy:', error);
  }

  // Parallel proxy requests for faster response
  const corsProxies = [
    'https://corsproxy.io/?', // Fastest first
    'https://api.allorigins.win/raw?url=',
    'https://cors-anywhere.herokuapp.com/'
  ];

  const proxyPromises = corsProxies.map(async proxy => {
    try {
      const proxyUrl = `${proxy}${encodeURIComponent(url)}`;
      const response = await fetch(proxyUrl, {
        headers: { 'Accept': 'image/*' },
        cache: 'no-cache'
      });
      
      if (response.ok) {
        proxyCache.set(url, proxyUrl); // Cache successful proxy
        return response;
      }
    } catch (error) {
      console.warn(`Proxy ${proxy} failed:`, error);
    }
    return null;
  });

  // Use the first successful response
  const responses = await Promise.all(proxyPromises);
  const successfulResponse = responses.find(r => r !== null);
  
  if (successfulResponse) return successfulResponse;
  throw new Error('All download attempts failed');
}

export async function downloadImage(imageUrl: string, options: DownloadOptions = {}) {
  const filename = options.filename || `rmz-ai-${Date.now()}.png`;

  try {
    let downloadUrl = imageUrl;
    const storagePath = getStoragePath(imageUrl);

    if (storagePath) {
      try {
        const storageRef = ref(storage, storagePath);
        downloadUrl = await getDownloadURL(storageRef);
      } catch (error) {
        console.warn('Failed to get Firebase download URL:', error);
      }
    }

    // Try download with optimized CORS proxy support
    try {
      const response = await fetchWithCorsProxy(downloadUrl);
      const contentType = response.headers.get('content-type');
      
      if (!contentType?.includes('image')) {
        throw new Error('Response is not an image');
      }

      const blob = await response.blob();
      if (!blob.size) {
        throw new Error('Empty image file');
      }

      await triggerDownload(blob, filename);
      return true;
    } catch (mainError) {
      // Quick fallback attempt if available
      if (options.fallbackUrl && options.fallbackUrl !== downloadUrl) {
        const response = await fetchWithCorsProxy(options.fallbackUrl);
        const blob = await response.blob();
        
        if (!blob.size) {
          throw new Error('Fallback image is empty');
        }

        await triggerDownload(blob, filename);
        return true;
      }
      throw mainError;
    }
  } catch (error) {
    console.error('Download failed:', error);
    
    // Immediate fallback to new tab
    if (options.fallbackUrl) {
      window.open(options.fallbackUrl, '_blank');
    } else {
      window.open(imageUrl, '_blank');
    }
    
    throw new Error('Failed to download image. Opened in new tab instead.');
  }
}
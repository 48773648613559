import { useState } from 'react';
import { upscaleApi } from '../api/upscale/service';

export function useUpscale() {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const upscaleImage = async (imageUrl: string, prompt?: string) => {
    try {
      setLoading(true);
      setError(null);

      const task = await upscaleApi.upscaleImage(imageUrl, prompt);
      let result: string | null = null;

      while (true) {
        const status = await upscaleApi.getTaskStatus(task.task_id);
        
        if (status.completed_steps && status.estimated_steps) {
          setProgress(Math.round((status.completed_steps / status.estimated_steps) * 100));
        }

        if (status.status === 'COMPLETED' && status.image_urls?.[0]) {
          result = status.image_urls[0];
          break;
        }

        if (status.status === 'FAILED' || status.error) {
          throw new Error(status.error || 'Upscale failed');
        }

        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      return result;
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to upscale image';
      setError(message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { upscaleImage, loading, progress, error };
}
import { z } from 'zod';

export const STYLE_CATEGORIES = {
  PHOTOGRAPHY: 'Photography',
  PAINTING: 'Painting',
  ILLUSTRATIONS: 'Illustrations',
  DRAWING: 'Drawing',
  '3D_RENDER': '3D',
  VECTOR: 'Vector',
  DESIGN: 'Design',
  ART_STYLES: 'Art'
} as const;

export const STYLE_VALUES = {
  NONE: 'No Style'
} as const;

export const STYLE_OPTIONS = [
  {
    category: STYLE_CATEGORIES.PHOTOGRAPHY,
    styles: [
      {
        id: 'photo_aerial',
        title: 'Aerial',
        value: 'Photography | Aerial',
        description: 'Aerial photography perspective',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Aerial.png?alt=media&token=91e7d3d5-5a48-4ec7-8e84-d811499572ba'
      },
      {
        id: 'photo_bw',
        title: 'Black and White',
        value: 'Photography | Black and White',
        description: 'Classic black and white photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Black%20and%20White.png?alt=media&token=e5f88421-424a-4076-a797-ffe351d0a0e9'
      },
      {
        id: 'photo_cinematic',
        title: 'Cinematic',
        value: 'Photography | Cinematic',
        description: 'Cinematic style photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Cinematic.png?alt=media&token=01db5e16-9c85-4fe9-905f-7b76185d094a'
      },
      {
        id: 'photo_fashion',
        title: 'Fashion',
        value: 'Photography | Fashion',
        description: 'Fashion photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Fashion.png?alt=media&token=4daddd77-3d45-48c9-bf6d-78c5d9297b3c'
      },
      {
        id: 'photo_landscape',
        title: 'Landscape',
        value: 'Photography | Landscape',
        description: 'Landscape photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Landscape.png?alt=media&token=96fef720-8610-4ab4-a51a-ace31a9bcd01'
      },
      {
        id: 'photo_long_exposure',
        title: 'Long Exposure',
        value: 'Photography | Long exposure',
        description: 'Long exposure photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Long%20exposure.png?alt=media&token=23379ef2-fc10-4ff1-8e69-9212f78632b8'
      },
      {
        id: 'photo_macro',
        title: 'Macro',
        value: 'Photography | Macro',
        description: 'Macro photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Macro.png?alt=media&token=9e24db7b-ee21-4578-b79b-87f2c14f70b3'
      },
      {
        id: 'photo_microscopic',
        title: 'Microscopic',
        value: 'Photography | Microscopic',
        description: 'Microscopic photography',
        image: 'https://images.unsplash.com/photo-1617137984095-74e4e5e3613f'
      },
      {
        id: 'photo_mockup',
        title: 'Mockup',
        value: 'Photography | Mockup',
        description: 'Product mockup photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Mockup.png?alt=media&token=68bd904a-4862-4699-b7be-b0469bd96216'
      },
      {
        id: 'photo_night',
        title: 'Night',
        value: 'Photography | Night',
        description: 'Night photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Night.png?alt=media&token=3d033a49-9464-475c-b230-0b922cfd68ed'
      },
      {
        id: 'photo_portrait',
        title: 'Portrait',
        value: 'Photography | Portrait',
        description: 'Portrait photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Portrait.png?alt=media&token=b28ba9ea-c772-44be-9b42-f90250f371f8'
      },
      {
        id: 'photo_portrait_cinematic',
        title: 'Portrait Cinematic',
        value: 'Photography | Portrait > Cinematic',
        description: 'Cinematic portrait photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Portrait%20%20Cinematic.png?alt=media&token=7621a830-7f45-497f-b6d5-6a68fb0bc157'
      },
      {
        id: 'photo_portrait_closeup',
        title: 'Portrait Closeup',
        value: 'Photography | Portrait > Closeup',
        description: 'Close-up portrait photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Portrait%20%20Closeup.png?alt=media&token=13781133-c130-4d5f-a553-75f531983568'
      },
      {
        id: 'photo_product',
        title: 'Product',
        value: 'Photography | Product',
        description: 'Product photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Product.png?alt=media&token=1c3f9cc1-892d-4a0f-947c-659821fa83f4'
      },
      {
        id: 'photo_reflection',
        title: 'Reflection',
        value: 'Photography | Reflection',
        description: 'Reflection photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Reflection.png?alt=media&token=1fbe2683-c3c5-4dda-820c-530dcac5a463'
      },
      {
        id: 'photo_sport',
        title: 'Sport',
        value: 'Photography | Sport',
        description: 'Sports photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Sport.png?alt=media&token=d9dcff7c-b3b9-4d36-ab4c-d3fe6608af54'
      },
      {
        id: 'photo_timelapse',
        title: 'Time-lapse',
        value: 'Photography | Time-lapse',
        description: 'Time-lapse photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Time-lapse.png?alt=media&token=0b2e2cd7-6102-4c5b-af70-724ceddb1c7f'
      },
      {
        id: 'photo_vintage',
        title: 'Vintage',
        value: 'Photography | Vintage',
        description: 'Vintage style photography',
        image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai-4d647.firebasestorage.app/o/Rmz.ai_resources%2FPhotography%20%20Vintage.png?alt=media&token=720d6d33-0f25-4e64-9b85-4fe2ffa228b9'
      }
    ]
  },
  {
    category: STYLE_CATEGORIES.PAINTING,
    styles: [
      {
        id: 'paint_acrylic',
        title: 'Acrylic',
        value: 'Painting | Acrylic',
        description: 'Acrylic painting style',
        image: 'https://images.unsplash.com/photo-1541961017774-22349e4a1262'
      },
      {
        id: 'paint_children',
        title: 'Children Book Art',
        value: 'Painting | Children Book Art',
        description: "Children's book illustration style",
        image: 'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6'
      },
      {
        id: 'paint_chinese',
        title: 'Chinese Ink Brush',
        value: 'Painting | Chinese Ink Brush',
        description: 'Traditional Chinese ink brush painting',
        image: 'https://images.unsplash.com/photo-1605721911519-3dfeb3be25e7'
      },
      {
        id: 'paint_digital',
        title: 'Digital',
        value: 'Painting | Digital',
        description: 'Digital painting',
        image: 'https://images.unsplash.com/photo-1513364776144-60967b0f800f'
      },
      {
        id: 'paint_fantasy',
        title: 'Fantasy Art',
        value: 'Painting | Fantasy Art',
        description: 'Fantasy art painting',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'paint_impressionism',
        title: 'Impressionism',
        value: 'Painting | Impressionism Painting Style',
        description: 'Impressionistic painting style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'paint_magic_realism',
        title: 'Magic Realism',
        value: 'Painting | Magic Realism',
        description: 'Magic realism painting style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'paint_oil',
        title: 'Oil Painting',
        value: 'Painting | Oil Painting',
        description: 'Oil painting style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'paint_palette_knife',
        title: 'Palette Knife',
        value: 'Painting | Palette Knife',
        description: 'Palette knife painting technique',
        image: 'https://images.unsplash.com/photo-1541961017774-22349e4a1262'
      },
      {
        id: 'paint_watercolor',
        title: 'Watercolor',
        value: 'Painting | Watercolor',
        description: 'Watercolor painting',
        image: 'https://images.unsplash.com/photo-1460661419201-fd4cecdf8a8b'
      }
    ]
  },
  {
    category: STYLE_CATEGORIES.ILLUSTRATIONS,
    styles: [
      {
        id: 'illust_anime',
        title: 'Anime',
        value: 'Illustration | Anime',
        description: 'Anime illustration style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'illust_anime_chibi',
        title: 'Anime Chibi',
        value: 'Illustration | Anime > Chibi',
        description: 'Chibi anime style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'illust_anime_kawaii',
        title: 'Anime Kawaii',
        value: 'Illustration | Anime > Kawaii',
        description: 'Kawaii anime style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'illust_anime_mecha',
        title: 'Anime Mecha',
        value: 'Illustration | Anime > Mecha',
        description: 'Mecha anime style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'illust_anime_realistic',
        title: 'Anime Realistic',
        value: 'Illustration | Anime > Realistic',
        description: 'Realistic anime style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'illust_cartoon',
        title: 'Cartoon',
        value: 'Illustration | Cartoon',
        description: 'Cartoon illustration style',
        image: 'https://images.unsplash.com/photo-1566277913310-9834504c22e7'
      },
      {
        id: 'illust_game_art',
        title: 'Game Art',
        value: 'Illustration | Game Art',
        description: 'Game art illustration style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'illust_sticker',
        title: 'Sticker',
        value: 'Illustration | Sticker',
        description: 'Sticker illustration style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'illust_sticker_cute',
        title: 'Sticker Cute',
        value: 'Illustration | Sticker Cute',
        description: 'Cute sticker illustration style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      },
      {
        id: 'illust_sticker_watercolor',
        title: 'Sticker Watercolor',
        value: 'Illustration | Sticker Watercolor',
        description: 'Watercolor sticker illustration style',
        image: 'https://images.unsplash.com/photo-1578632767115-351597cf2477'
      }
    ]
  },
  {
    category: STYLE_CATEGORIES.DRAWING,
    styles: [
      {
        id: 'draw_pen',
        title: 'Pen',
        value: 'Drawing | Pen',
        description: 'Pen drawing',
        image: 'https://images.unsplash.com/photo-1544982503-9f984c14501a'
      },
      {
        id: 'draw_pencil',
        title: 'Pencil',
        value: 'Drawing | Pencil',
        description: 'Pencil drawing',
        image: 'https://images.unsplash.com/photo-1572883454114-1cf0031ede2a'
      },
      {
        id: 'draw_charcoal',
        title: 'Charcoal',
        value: 'Drawing | Charcoal',
        description: 'Charcoal drawing',
        image: 'https://images.unsplash.com/photo-1513364776144-60967b0f800f'
      },
      {
        id: 'draw_scratchboard',
        title: 'Scratchboard',
        value: 'Drawing | Scratchboard',
        description: 'Scratchboard drawing technique',
        image: 'https://images.unsplash.com/photo-1572883454114-1cf0031ede2a'
      },
      {
        id: 'draw_ballpoint',
        title: 'Blue Ballpoint Pen',
        value: 'Drawing | Blue Ballpoint Pen',
        description: 'Blue ballpoint pen drawing',
        image: 'https://images.unsplash.com/photo-1544982503-9f984c14501a'
      }
    ]
  },
  {
    category: STYLE_CATEGORIES['3D_RENDER'],
    styles: [
      {
        id: '3d_abstract',
        title: 'Abstract',
        value: '3D | Abstract',
        description: '3D abstract art',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: '3d_abstract_soft',
        title: 'Abstract Soft Forms',
        value: '3D | Abstract > Soft Forms',
        description: '3D abstract soft forms',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: '3d_architectural',
        title: 'Architectural',
        value: '3D | Architectural',
        description: '3D architectural visualization',
        image: 'https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e'
      },
      {
        id: '3d_character',
        title: 'Character',
        value: '3D | Character',
        description: '3D character design',
        image: 'https://images.unsplash.com/photo-1599707367072-cd6ada2bc375'
      },
      {
        id: '3d_character_cartoon',
        title: 'Character Cartoon Cute',
        value: '3D | Character > Cartoon Cute',
        description: '3D cute cartoon character',
        image: 'https://images.unsplash.com/photo-1599707367072-cd6ada2bc375'
      },
      {
        id: '3d_game_asset',
        title: 'Game Asset',
        value: '3D | Game Asset',
        description: '3D game asset',
        image: 'https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e'
      },
      {
        id: '3d_icon',
        title: 'Icon',
        value: '3D | Icon',
        description: '3D icon design',
        image: 'https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e'
      },
      {
        id: '3d_isometric',
        title: 'Isometric',
        value: '3D | Isometric',
        description: '3D isometric style',
        image: 'https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e'
      },
      {
        id: '3d_low_poly',
        title: 'Low Poly',
        value: '3D | Low Poly',
        description: '3D low poly style',
        image: 'https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e'
      },
      {
        id: '3d_model',
        title: 'Model',
        value: '3D | Model',
        description: '3D model',
        image: 'https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e'
      }
    ]
  },
  {
    category: STYLE_CATEGORIES.VECTOR,
    styles: [
      {
        id: 'vector_line',
        title: 'Line Art',
        value: 'Vector | Line Art',
        description: 'Vector line art',
        image: 'https://images.unsplash.com/photo-1572883454114-1cf0031ede2a'
      },
      {
        id: 'vector_flat',
        title: 'Flat Art Style',
        value: 'Vector | Flat Art Style',
        description: 'Flat vector art style',
        image: 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe'
      },
      {
        id: 'vector_logo_symbol',
        title: 'Logo Symbol',
        value: 'Vector | Logo Symbol',
        description: 'Vector logo symbol',
        image: 'https://images.unsplash.com/photo-1572883454114-1cf0031ede2a'
      },
      {
        id: 'vector_silhouette',
        title: 'Silhouette',
        value: 'Vector | Silhouette',
        description: 'Vector silhouette style',
        image: 'https://images.unsplash.com/photo-1572883454114-1cf0031ede2a'
      },
      {
        id: 'vector_silhouette_cute',
        title: 'Silhouette Cute',
        value: 'Vector | Silhouette Cute',
        description: 'Cute vector silhouette style',
        image: 'https://images.unsplash.com/photo-1572883454114-1cf0031ede2a'
      }
    ]
  },
  {
    category: STYLE_CATEGORIES.DESIGN,
    styles: [
      {
        id: 'design_avatar',
        title: 'Avatar',
        value: 'Design | Avatar',
        description: 'Avatar design',
        image: 'https://images.unsplash.com/photo-1566277913310-9834504c22e7'
      },
      {
        id: 'design_banner',
        title: 'Banner',
        value: 'Design | Banner',
        description: 'Banner design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_brochure',
        title: 'Brochure',
        value: 'Design | Brochure',
        description: 'Brochure design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_business_card',
        title: 'Business Card',
        value: 'Design | Business card',
        description: 'Business card design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_event_invitation',
        title: 'Event Invitation',
        value: 'Design | Event Invitation',
        description: 'Event invitation design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_flyer',
        title: 'Flyer',
        value: 'Design | Flyer',
        description: 'Flyer design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_icon',
        title: 'Icon Design',
        value: 'Design | Icon Design',
        description: 'Icon design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_icon_simple',
        title: 'Simple Black Icon',
        value: 'Design | Icon Design Simple Black',
        description: 'Simple black icon design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_infographic',
        title: 'Infographic',
        value: 'Design | Infographic',
        description: 'Infographic design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_letterhead',
        title: 'Letterhead',
        value: 'Design | Letterhead',
        description: 'Letterhead design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_menu',
        title: 'Menu',
        value: 'Design | Menu',
        description: 'Menu design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      },
      {
        id: 'design_website',
        title: 'Website',
        value: 'Design | Website',
        description: 'Website design',
        image: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e'
      }
    ]
  },
  {
    category: STYLE_CATEGORIES.ART_STYLES,
    styles: [
      {
        id: 'art_cartoon',
        title: 'Cartoon',
        value: 'ART | Cartoon',
        description: 'Cartoon art style',
        image: 'https://images.unsplash.com/photo-1566277913310-9834504c22e7'
      },
      {
        id: 'art_comic',
        title: 'Comic Book',
        value: 'ART | Comic Book Style',
        description: 'Comic book art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'art_cubism',
        title: 'Cubism',
        value: 'ART | Cubism',
        description: 'Cubism art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'art_cybergoth',
        title: 'Cybergoth',
        value: 'ART | Cybergoth Art',
        description: 'Cybergoth art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'art_cyberpunk',
        title: 'Cyberpunk',
        value: 'ART | Cyberpunk',
        description: 'Cyberpunk art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'art_futurism',
        title: 'Futurism',
        value: 'ART | Futurism',
        description: 'Futurism art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'art_glitch',
        title: 'Glitch',
        value: 'ART | Glitch',
        description: 'Glitch art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'art_interstellar',
        title: 'Interstellar',
        value: 'ART | Interstellar',
        description: 'Interstellar art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'art_pixel',
        title: 'Pixel Art',
        value: 'ART | Pixel Art',
        description: 'Pixel art style',
        image: 'https://images.unsplash.com/photo-1550009158-9ebf69173e03'
      },
      {
        id: 'art_pop',
        title: 'Pop Art',
        value: 'ART | Pop Art',
        description: 'Pop art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      },
      {
        id: 'art_street',
        title: 'Street Art',
        value: 'ART | Street Art',
        description: 'Street art style',
        image: 'https://images.unsplash.com/photo-1579783901586-d88db74b4fe4'
      }
    ]
  }
];

// Create a union type of all possible style values
const allStyleValues = [
  'No Style',
  ...STYLE_OPTIONS.flatMap(category => 
    category.styles.map(style => style.value)
  )
] as const;

export const styleSchema = z.enum(allStyleValues);

export type StyleValue = z.infer<typeof styleSchema>;

export function validateStyle(style: unknown): StyleValue {
  try {
    return styleSchema.parse(style);
  } catch {
    return STYLE_VALUES.NONE;
  }
}
import { Palette, ArrowRight, Sparkles, Layers, Wand2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function CanvasComingSoon() {
  const navigate = useNavigate();

  const features = [
    {
      icon: Layers,
      title: 'Layer Management',
      description: 'Create complex compositions with multiple layers'
    },
    {
      icon: Wand2,
      title: 'AI-Powered Editing',
      description: 'Smart tools for seamless image manipulation'
    },
    {
      icon: Sparkles,
      title: 'Advanced Effects',
      description: 'Professional-grade filters and effects'
    }
  ];

  return (
    <div className="fixed inset-0 bg-gray-50 dark:bg-rmz-darker flex items-center justify-center p-4 overflow-auto">
      {/* Background Gradient Orbs */}
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[300px] h-[300px] bg-[#FF3B8B] rounded-full filter blur-[150px] opacity-10" />
        <div className="absolute bottom-0 right-0 w-[300px] h-[300px] bg-[#00F5D4] rounded-full filter blur-[150px] opacity-10" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[300px] h-[300px] bg-[#B86EFF] rounded-full filter blur-[150px] opacity-10" />
      </div>

      <div className="relative max-w-4xl w-full mx-auto space-y-12 my-8">
        {/* Main Content Card */}
        <div className="relative">
          <div className="absolute -inset-1 rounded-2xl bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] opacity-20 blur-lg" />
          <div className="relative bg-white dark:bg-rmz-gray rounded-2xl overflow-hidden">
            {/* Decorative Header */}
            <div className="h-2 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4]" />
            
            <div className="p-8 md:p-12">
              <div className="flex flex-col items-center text-center mb-12">
                <div className="relative mb-8">
                  <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] animate-pulse opacity-25 blur-xl" />
                  <div className="relative p-4 bg-rmz-primary/10 rounded-xl">
                    <Palette className="w-16 h-16 text-rmz-primary" />
                  </div>
                </div>
                
                <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] mb-4">
                  Canvas Feature Coming Soon
                </h1>
                
                <p className="text-lg text-gray-600 dark:text-gray-400 max-w-2xl">
                  We're crafting a powerful canvas experience that will revolutionize how you create AI-generated compositions. Get ready for something extraordinary!
                </p>
              </div>

              {/* Feature Grid */}
              <div className="grid md:grid-cols-3 gap-8 mb-12">
                {features.map((feature, index) => (
                  <div 
                    key={index}
                    className="relative group"
                  >
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] rounded-lg opacity-0 group-hover:opacity-100 transition-opacity blur" />
                    <div className="relative p-6 bg-white dark:bg-rmz-gray-light rounded-lg border border-gray-200 dark:border-rmz-gray-light">
                      <feature.icon className="w-8 h-8 text-rmz-primary mb-4" />
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                        {feature.title}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-400 text-sm">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* CTA Section */}
              <div className="text-center">
                <button
                  onClick={() => navigate('/create')}
                  className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-white rounded-full font-medium hover:opacity-90 transition-opacity group"
                >
                  <span>Try Our Image Generation Tool</span>
                  <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Progress Indicator */}
        <div className="flex items-center justify-center gap-4 text-sm text-gray-500 dark:text-gray-400">
          <div className="w-2 h-2 rounded-full bg-rmz-primary animate-pulse" />
          <span>Development in progress</span>
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Instagram, Sparkles, Rocket, Users2, Zap, Youtube, MessageCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { Header } from './shared/Header';
import { rmzLogoFull } from '../lib/assets/logo';
import { ForWhoSection } from './ForWhoSection';
import { AnimatedBackground } from './ui/animated-background';

export default function HomePage() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleLaunch = () => {
    navigate(user ? '/create' : '/login');
  };

  const handleDiscordClick = () => {
    window.open('https://discord.gg/gnq7yr25', '_blank');
  };

  return (
    <AnimatedBackground>
      <Header />
      {/* Beta Login Button - Above Hero Section */}
      <div className="container mx-auto px-4 pt-32 pb-4">
        <div className="flex flex-col md:flex-row gap-8 max-w-4xl mx-auto">
          <div className="flex-1">
            <motion.button
              onClick={() => navigate('/beta-code')}
              className="relative w-full h-24 group overflow-hidden rounded-2xl cursor-pointer z-0"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              {/* Animated gradient border */}
              <div className="absolute inset-0 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] opacity-30 group-hover:opacity-100 transition-all duration-500" />
              
              {/* Inner background with glass effect */}
              <div className="absolute inset-[2px] bg-[#1a0b2e]/90 backdrop-blur-sm rounded-2xl" />
              
              {/* Animated shine effect */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <div className="absolute inset-0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-white/10 to-transparent transform -skew-x-12" />
              </div>
              
              {/* Content */}
              <div className="absolute inset-0 flex items-center justify-center gap-3">
                <Rocket className="w-8 h-8 text-[#00F5D4] group-hover:animate-pulse" />
                <span className="bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-transparent bg-clip-text text-3xl font-bold">
                  Launch rmz.ai
                </span>
              </div>
            </motion.button>
          </div>
          
          <div className="flex-1">
            <motion.button
              onClick={() => navigate('/beta')}
              className="relative w-full h-24 group overflow-hidden rounded-2xl cursor-pointer z-0"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              {/* Animated gradient border */}
              <div className="absolute inset-0 bg-gradient-to-r from-[#00F5D4] via-[#B86EFF] to-[#FF3B8B] opacity-30 group-hover:opacity-100 transition-all duration-500" />
              
              {/* Inner background with glass effect */}
              <div className="absolute inset-[2px] bg-[#1a0b2e]/90 backdrop-blur-sm rounded-2xl" />
              
              {/* Animated shine effect */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <div className="absolute inset-0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-white/10 to-transparent transform -skew-x-12" />
              </div>
              
              {/* Content */}
              <div className="absolute inset-0 flex items-center justify-center gap-3">
                <Sparkles className="w-8 h-8 text-[#00F5D4] group-hover:animate-pulse" />
                <span className="bg-gradient-to-r from-[#00F5D4] via-[#B86EFF] to-[#FF3B8B] text-transparent bg-clip-text text-3xl font-bold">
                  APPLY FOR BETA ACCESS
                </span>
              </div>
            </motion.button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="relative z-10">
        {/* App Preview */}
        <div className="py-32">
          <div className="container mx-auto px-4">
            <div className="relative">
              {/* Outermost glow - very soft and wide */}
              <div className="absolute -inset-48 bg-gradient-to-r from-[#00F5D4]/5 via-[#B86EFF]/5 to-[#FF3B8B]/5 blur-[200px]"></div>
              
              {/* Outer glow */}
              <div className="absolute -inset-24 bg-gradient-to-r from-[#00F5D4]/10 via-[#B86EFF]/10 to-[#FF3B8B]/10 blur-[100px]"></div>
              
              {/* Middle glow */}
              <div className="absolute -inset-12 bg-gradient-to-r from-[#00F5D4]/15 via-[#B86EFF]/15 to-[#FF3B8B]/15 blur-[50px]"></div>
              
              {/* Inner glow */}
              <div className="absolute -inset-6 bg-gradient-to-r from-[#00F5D4]/20 via-[#B86EFF]/20 to-[#FF3B8B]/20 blur-[25px]"></div>
              
              {/* Image */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-main.png?alt=media&token=1dc2158d-ae0c-4227-b04b-aa7bdada2da4"
                alt="RMZ.AI Interface"
                className="relative w-full rounded-2xl"
              />
            </div>
          </div>
        </div>

        {/* For Who Section */}
        <ForWhoSection />

        {/* Social Links */}
        <div className="py-16 space-y-8">
          {/* Discord Button */}
          <div className="container mx-auto px-4">
            <button
              onClick={() => window.open('https://discord.gg/efPbhtte', '_blank')}
              className="relative w-full max-w-4xl mx-auto block group transform hover:scale-[1.02] transition-all duration-300"
            >
              <div className="absolute -inset-1 bg-gradient-to-r from-[#5865F2] to-[#7289DA] rounded-2xl opacity-75 group-hover:opacity-100 blur-lg transition-all duration-300"></div>
              <div className="relative px-8 py-6 bg-[#36393F] rounded-2xl border border-[#5865F2]/20 text-white text-center group-hover:border-[#5865F2]/40 transition-all duration-300">
                <div className="flex items-center justify-center gap-3 text-2xl font-medium">
                  <MessageCircle className="w-8 h-8" />
                  <span>Be part of a creative community - Join Discord Server</span>
                </div>
              </div>
            </button>
          </div>

          {/* YouTube Link */}
          <div className="container mx-auto px-4">
            <button
              onClick={() => window.open('https://youtube.com/@rmz.ai', '_blank')}
              className="relative w-full max-w-4xl mx-auto block group transform hover:scale-[1.02] transition-all duration-300"
            >
              <div className="absolute -inset-1 bg-gradient-to-r from-[#FF0000] to-[#FF4444] rounded-2xl opacity-75 group-hover:opacity-100 blur-lg transition-all duration-300"></div>
              <div className="relative px-8 py-6 bg-[#282828] rounded-2xl border border-[#FF0000]/20 text-white text-center group-hover:border-[#FF0000]/40 transition-all duration-300">
                <div className="flex items-center justify-center gap-3 text-2xl font-medium">
                  <Youtube className="w-8 h-8" />
                  <span>Learn how - link to Youtube</span>
                </div>
              </div>
            </button>
          </div>
        </div>

        {/* Footer */}
        <footer className="py-16 border-t border-white/10">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto text-center space-y-8">
              <img 
                src={rmzLogoFull}
                alt="RMZ.AI"
                className="h-12 mx-auto"
              />
              <div className="flex items-center justify-center gap-8 text-gray-400">
                <a href="/privacy" className="hover:text-white transition-colors">Privacy Policy</a>
                <a href="/terms" className="hover:text-white transition-colors">Terms of Service</a>
                <a href="/contact" className="hover:text-white transition-colors">Contact</a>
              </div>
              <p className="text-gray-500 text-sm">
                © {new Date().getFullYear()} RMZ.AI. All rights reserved.
              </p>
            </div>
          </div>
        </footer>

        </div>
    </AnimatedBackground>
  );
}
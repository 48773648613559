import { useEffect } from 'react';
import { cn } from '../../lib/utils';

interface AnimatedBackgroundProps {
  children: React.ReactNode;
  className?: string;
}

export function AnimatedBackground({ children, className }: AnimatedBackgroundProps) {
  return (
    <div className={cn("relative min-h-screen w-full overflow-hidden bg-rmz-darker", className)}>
      {/* Animated Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Primary Orb - Pink */}
        <div className="absolute -top-[40%] -left-[20%] w-[80%] h-[80%] rounded-full bg-[#FF3B8B] opacity-30 blur-[120px] animate-float" />
        
        {/* Secondary Orb - Purple */}
        <div className="absolute top-[20%] -right-[20%] w-[70%] h-[70%] rounded-full bg-[#B86EFF] opacity-20 blur-[120px] animate-float-delayed" />
        
        {/* Tertiary Orb - Cyan */}
        <div className="absolute -bottom-[40%] left-[20%] w-[60%] h-[60%] rounded-full bg-[#00F5D4] opacity-25 blur-[120px] animate-float-reverse" />
        
        {/* Additional Orbs for depth */}
        <div className="absolute top-[40%] left-[10%] w-[40%] h-[40%] rounded-full bg-[#FF3B8B] opacity-15 blur-[80px] animate-pulse-slow" />
        <div className="absolute bottom-[20%] right-[30%] w-[50%] h-[50%] rounded-full bg-[#B86EFF] opacity-10 blur-[100px] animate-float-slow" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
}
import axios from 'axios';
import { API_CONFIG } from './config';

const api = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  headers: {
    Authorization: `Bearer ${API_CONFIG.API_KEY}`,
    'Content-Type': 'application/json',
  },
});

export interface UpscaleTaskResponse {
  task_id: string;
  status: 'CREATED' | 'IN_QUEUE' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED';
  estimated_steps: number;
  completed_steps?: number;
  image_urls?: string[];
  video_urls?: string[];
  delay_time?: number;
  execution_time?: number;
  cost?: number;
  error?: string;
}

function cleanBase64Image(dataUrl: string): string {
  // If it's already a URL, return as is
  if (dataUrl.startsWith('http')) {
    return dataUrl;
  }
  
  // If it's a data URL, extract just the base64 part
  if (dataUrl.startsWith('data:')) {
    const base64Data = dataUrl.split(',')[1];
    if (!base64Data) {
      throw new Error('Invalid data URL format');
    }
    return base64Data;
  }
  
  // If it's already base64 data, return as is
  return dataUrl;
}

export const upscaleApi = {
  async upscaleImage(imageUrl: string, prompt?: string): Promise<UpscaleTaskResponse> {
    try {
      // Clean and validate the image data
      const imageData = cleanBase64Image(imageUrl);

      const response = await api.post('/run_task', {
        inputs: {
          [API_CONFIG.INPUT_IDS.PROMPT]: {
            title: "Prompt",
            value: prompt || ''
          },
          [API_CONFIG.INPUT_IDS.IMAGE]: {
            title: "Load Image",
            value: imageData
          }
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          throw new Error('Invalid image format. Please try a different image.');
        }
        throw new Error(error.response?.data?.error || 'Failed to upscale image');
      }
      throw error;
    }
  },

  async getTaskStatus(taskId: string): Promise<UpscaleTaskResponse> {
    const response = await api.get(`/task_status/${taskId}`);
    return response.data;
  },
};
interface RetryOptions {
  retries?: number;
  delay?: number;
  backoff?: boolean;
  onRetry?: (error: Error, attempt: number) => void;
}

export function retry<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  options: RetryOptions = {}
): T {
  const {
    retries = 3,
    delay = 1000,
    backoff = true,
    onRetry = () => {}
  } = options;

  return (async (...args: Parameters<T>): Promise<ReturnType<T>> => {
    let lastError: Error | null = null;

    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        return await fn(...args);
      } catch (error) {
        lastError = error instanceof Error ? error : new Error('Unknown error');
        
        if (attempt < retries - 1) {
          onRetry(lastError, attempt + 1);
          
          const waitTime = backoff
            ? delay * Math.pow(2, attempt)
            : delay;
            
          await new Promise(resolve => setTimeout(resolve, waitTime));
        }
      }
    }

    throw lastError || new Error('All retry attempts failed');
  }) as T;
}
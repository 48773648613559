import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles, Loader2, Key } from 'lucide-react';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db, listenToBetaApplication } from '../lib/firebase';
import { rmzLogoFull } from '../lib/assets/logo';
import { AnimatedBackground } from './ui/animated-background';

export function BetaCodePage() {
  const navigate = useNavigate();
  const [accessCode, setAccessCode] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [applicationId, setApplicationId] = useState<string | null>(null);

  // Set up listener when applicationId changes
  useEffect(() => {
    if (!applicationId) return;

    const unsubscribe = listenToBetaApplication(applicationId);
    return () => unsubscribe();
  }, [applicationId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!accessCode.trim() || !email.trim()) {
      setError('Please enter both your access code and email');
      return;
    }

    // Validate email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // First, check if the email has a beta application
      const betaContactsRef = collection(db, 'beta-contacts');
      const contactQuery = query(
        betaContactsRef,
        where('email', '==', email.trim().toLowerCase()),
        where('accessCode', '==', accessCode.trim().toUpperCase())
      );

      const contactSnapshot = await getDocs(contactQuery);
      
      if (contactSnapshot.empty) {
        setError('Invalid access code or email combination');
        return;
      }

      const betaContact = contactSnapshot.docs[0];
      setApplicationId(betaContact.id);
      const betaData = betaContact.data();

      // Check if the application is approved and has an access code
      if (!betaData.isApproved || !betaData.accessCode) {
        setError('Your beta application is still pending approval');
        return;
      }

      // Verify the access code matches
      if (betaData.accessCode !== accessCode.trim().toUpperCase()) {
        setError('Invalid access code');
        return;
      }

      // Store both the access code and email in session storage
      sessionStorage.setItem('beta_access', 'true');
      sessionStorage.setItem('beta_email', email.trim().toLowerCase());
      
      // Redirect to login page
      navigate('/login');
    } catch (err) {
      console.error('Error verifying access code:', err);
      setError('Failed to verify access code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimatedBackground>
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="relative w-full max-w-md">
          <img 
            src={rmzLogoFull} 
            alt="RMZ.AI" 
            className="h-16 w-auto mx-auto mb-12 cursor-pointer" 
            onClick={() => navigate('/')}
          />

          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold text-white mb-2">
              Enter Beta Access Code
            </h2>
            <p className="text-gray-400">
              Please enter your beta access code to continue
            </p>
          </div>

          <div className="bg-rmz-gray/80 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-rmz-gray-light">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-3 bg-rmz-gray border border-rmz-gray-light rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#B86EFF]/50 focus:border-transparent"
                    placeholder="Enter your email"
                    required
                  />
                </div>

                <div className="relative">
                  <Key className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                    className="w-full pl-10 pr-4 py-3 bg-rmz-gray border border-rmz-gray-light rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#B86EFF]/50 focus:border-transparent"
                    placeholder="Enter your access code"
                    required
                  />
                </div>
              </div>

              {error && (
                <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 text-sm">
                  {error}
                </div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-white font-medium py-3 px-4 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Verifying...
                  </>
                ) : (
                  <>
                    <Sparkles className="w-5 h-5" />
                    Continue
                  </>
                )}
              </button>

              <div className="text-center">
                <button
                  type="button"
                  onClick={() => navigate('/beta-contact')}
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Don't have a code? Apply for beta access
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AnimatedBackground>
  );
}
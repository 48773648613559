import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  User,
  ActionCodeSettings
} from 'firebase/auth';
import { auth } from './config';

const googleProvider = new GoogleAuthProvider();

// Email verification configuration
const actionCodeSettings: ActionCodeSettings = {
  url: 'https://rmz-ai-4d647.firebaseapp.com/login',
  handleCodeInApp: false
};

export const signUp = async (email: string, password: string) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    
    // Send verification email with custom settings
    if (result.user) {
      await sendEmailVerification(result.user, actionCodeSettings);
    }
    
    return result.user;
  } catch (error: any) {
    let message = 'Failed to create account';
    if (error.code === 'auth/email-already-in-use') {
      message = 'This email is already registered. Please sign in instead.';
    } else if (error.code === 'auth/weak-password') {
      message = 'Password should be at least 6 characters long.';
    } else if (error.code === 'auth/invalid-email') {
      message = 'Please enter a valid email address.';
    }
    throw new Error(message);
  }
};

export const signIn = async (email: string, password: string): Promise<any> => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    
    return result;
  } catch (error: any) {
    let message = 'Failed to sign in';
    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
      message = 'Invalid email or password.';
    } else if (error.code === 'auth/invalid-email') {
      message = 'Please enter a valid email address.';
    } else if (error.code === 'auth/too-many-requests') {
      message = 'Too many failed attempts. Please try again later.';
    } else if (error.code === 'auth/invalid-credential') {
      message = 'Invalid email or password.';
    }
    throw new Error(message);
  }
};

export const signInWithGoogle = async (): Promise<any> => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return result;
  } catch (error: any) {
    let message = 'Failed to sign in with Google';
    if (error.code === 'auth/popup-blocked') {
      message = 'Please allow popups for this website to sign in with Google.';
    }
    throw new Error(message);
  }
};

export const resendVerificationEmail = async (user: User) => {
  try {
    await sendEmailVerification(user, actionCodeSettings);
  } catch (error: any) {
    let message = 'Failed to send verification email';
    if (error.code === 'auth/too-many-requests') {
      message = 'Too many verification emails sent. Please wait a few minutes and try again.';
    }
    throw new Error(message);
  }
};
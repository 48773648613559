import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpscale } from '../lib/hooks/useUpscale';
import { Upload, Loader2, AlertCircle, Download } from 'lucide-react';
import { Header } from './shared/Header';
import { uploadGeneratedImage } from '../lib/firebase/storage';
import { saveUpscaledImage, saveTempUpload } from '../lib/firebase/db';
import { useAuth } from '../contexts/AuthContext';

export function UpscalePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { upscaleImage, loading, progress, error } = useUpscale();
  const { user } = useAuth();
  
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [originalImageUrl, setOriginalImageUrl] = useState<string | null>(null);
  const [prompt, setPrompt] = useState('');
  const [result, setResult] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [saved, setSaved] = useState(false);

  // Handle generation passed from gallery
  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageData = sessionStorage.getItem('upscale_image');
        if (!imageData) return;

        const { imageUrl, prompt } = JSON.parse(imageData);
        setSelectedImage(imageUrl);
        setOriginalImageUrl(imageUrl);
        setPrompt(prompt || '');
        sessionStorage.removeItem('upscale_image');
      } catch (error) {
        console.error('Failed to load image data:', error);
      }
    };

    loadImage();
  }, []);

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user) return;

    try {
      setUploading(true);
      setSaveError(null);
      
      const reader = new FileReader();
      reader.onload = async (e) => {
        const imageDataUrl = e.target?.result as string;
        
        // Keep original data URL for preview
        setSelectedImage(imageDataUrl);
        
        try {
          // Save to temp-uploads collection
          const tempUploadUrl = await saveTempUpload(user.uid, imageDataUrl);
          setOriginalImageUrl(tempUploadUrl);
        } catch (error) {
          console.error('Failed to save temporary upload:', error);
          setSaveError('Failed to upload image. Please try again.');
          setSelectedImage(null);
        }
        
        setResult(null);
        setSaved(false);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Failed to upload file:', error);
      setSaveError('Failed to process image. Please try again.');
    } finally {
      setUploading(false);
      event.target.value = '';
    }
  };

  const handleUpscale = async () => {
    if (!selectedImage || !originalImageUrl || !user) return;
    
    try {
      const result = await upscaleImage(selectedImage, prompt);
      if (!result) return;

      setSaving(true);
      setSaveError(null);

      // Upload to Firebase Storage in upscaled-images path
      const firebaseUrl = await uploadGeneratedImage(
        user.uid,
        result,
        'upscaled'
      );
      setResult(firebaseUrl);

      // Save to upscaled collection
      await saveUpscaledImage(user.uid, {
        originalImageUrl: originalImageUrl,
        upscaledImageUrl: firebaseUrl,
        prompt: prompt || 'Upscaled image',
        metadata: {
          type: 'upscale',
          timestamp: Date.now()
        }
      });

      setSaved(true);
    } catch (error) {
      console.error('Failed to save upscale result:', error);
      setSaveError('Failed to save upscaled image. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleReset = () => {
    setSelectedImage(null);
    setOriginalImageUrl(null);
    setPrompt('');
    setResult(null);
    setSaveError(null);
    setSaved(false);
  };

  return (
    <div className="min-h-screen pt-16 bg-gray-50 dark:bg-rmz-darker">
      <Header />
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Upscale Image</h1>
          <div className="flex items-center gap-4">
            <button
              onClick={() => navigate('/create')}
              className="px-4 py-2 text-sm text-gray-400 hover:text-white transition-colors"
            >
              Back to Create
            </button>
            <button
              onClick={handleReset}
              className="px-4 py-2 text-sm text-gray-400 hover:text-white transition-colors"
            >
              Reset
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column - Input Section */}
          <div className="space-y-6">
            <div className="bg-white dark:bg-rmz-gray rounded-xl p-6 border border-gray-200 dark:border-rmz-gray-light">
              <div className="space-y-6">
                {/* Image Upload */}
                <div>
                  <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Upload Image</h2>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelect}
                    className="hidden"
                    disabled={uploading}
                  />
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    disabled={uploading}
                    className="w-full aspect-square border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg flex items-center justify-center hover:border-rmz-primary transition-colors relative overflow-hidden disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {selectedImage ? (
                      <>
                        <img
                          src={selectedImage}
                          alt="Preview"
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-black/50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                          <div className="text-center text-white">
                            <Upload className="w-8 h-8 mx-auto mb-2" />
                            <span className="text-sm">Click to change image</span>
                          </div>
                        </div>
                      </>
                    ) : uploading ? (
                      <div className="text-center">
                        <Loader2 className="w-8 h-8 mx-auto mb-2 text-rmz-primary animate-spin" />
                        <span className="text-sm text-gray-400">
                          Uploading image...
                        </span>
                      </div>
                    ) : (
                      <div className="text-center">
                        <Upload className="w-8 h-8 mx-auto mb-2 text-gray-400" />
                        <span className="text-sm text-gray-400">
                          Click to upload or drag and drop
                        </span>
                      </div>
                    )}
                  </button>
                </div>

                {/* Optional Prompt */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Prompt (Optional)
                  </label>
                  <textarea
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Add details to guide the upscaling process..."
                    className="w-full px-4 py-3 bg-white dark:bg-rmz-gray-light border border-gray-200 dark:border-rmz-gray-light rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-rmz-primary/50 resize-none h-24"
                  />
                </div>

                {/* Upscale Button */}
                <button
                  onClick={handleUpscale}
                  disabled={!originalImageUrl || loading || saving || uploading}
                  className="w-full px-6 py-3 bg-gradient-primary text-rmz-dark rounded-lg font-medium disabled:opacity-50 flex items-center justify-center gap-2"
                >
                  {loading ? (
                    <>
                      <Loader2 className="w-5 h-5 animate-spin" />
                      Upscaling ({progress}%)
                    </>
                  ) : saving ? (
                    <>
                      <Loader2 className="w-5 h-5 animate-spin" />
                      Saving...
                    </>
                  ) : uploading ? (
                    'Preparing image...'
                  ) : (
                    'Upscale Image'
                  )}
                </button>

                {/* Error Messages */}
                {(error || saveError) && (
                  <div className="p-4 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg text-red-600 dark:text-red-400 text-sm flex items-center gap-2">
                    <AlertCircle className="w-5 h-5 flex-shrink-0" />
                    <span>{error || saveError}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Right Column - Result Section */}
          <div className="bg-white dark:bg-rmz-gray rounded-xl p-6 border border-gray-200 dark:border-rmz-gray-light">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Result</h2>
            <div className="aspect-square rounded-lg overflow-hidden bg-gray-100 dark:bg-rmz-gray-light relative">
              {loading ? (
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <Loader2 className="w-8 h-8 text-rmz-primary animate-spin mb-4" />
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    Upscaling your image... {progress}%
                  </div>
                </div>
              ) : result ? (
                <img
                  src={result}
                  alt="Upscaled"
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="absolute inset-0 flex items-center justify-center text-gray-400">
                  Upscaled image will appear here
                </div>
              )}
            </div>

            {/* Success Message */}
            {saved && (
              <div className="mt-4 flex items-center justify-between">
                <p className="text-sm text-green-500">
                  Successfully saved to gallery!
                </p>
                <button
                  onClick={() => navigate('/create', { state: { showGallery: true } })}
                  className="px-4 py-2 text-sm text-rmz-primary hover:text-rmz-primary/80 transition-colors"
                >
                  View in Gallery
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import type { TaskStatus } from '../lib/types';
import { GenerationCard } from './GenerationCard';
import { useAuth } from '../contexts/AuthContext';
import { Header } from './shared/Header';
import { getUserGenerations } from '../lib/firebase/db';

export function Gallery() {
  const { user } = useAuth();
  const [generations, setGenerations] = useState<TaskStatus[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      getUserGenerations(user.uid)
        .then(gens => setGenerations(gens))
        .catch(error => console.error('Error loading gallery:', error))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const handleGenerationDeleted = (deletedId: string) => {
    setGenerations(prev => prev.filter(gen => gen.task_id !== deletedId));
  };

  const handleImageDeleted = (generationId: string, deletedImageUrl: string) => {
    setGenerations(prev => prev.map(gen => 
      gen.task_id === generationId
        ? {
            ...gen,
            image_urls: gen.image_urls.filter(url => url !== deletedImageUrl)
          }
        : gen
    ));
  };

  if (generations.length === 0) {
    return (
      <div className="min-h-screen pt-16 bg-gray-50 dark:bg-rmz-darker">
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-8">My Gallery</h1>
          <div className="text-center py-16">
        <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">No creations yet</h2>
        <p className="text-gray-600 dark:text-gray-400">
          Your generated images will appear here. Start by creating your first image!
        </p>
          </div>
        </div>
      </div>
    );
  }

  const handleDelete = async (generationId: string, imageUrl: string, allImageUrls: string[]) => {
    if (allImageUrls.length === 1) {
      handleGenerationDeleted(generationId);
    } else {
      handleImageDeleted(generationId, imageUrl);
    }
  };

  return (
    <div className="min-h-screen pt-32 bg-gray-50 dark:bg-rmz-darker">
      <Header />
      <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold mb-8 text-gray-900 dark:text-white">Creations</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {generations.map((generation) => (
          <GenerationCard
            key={generation.task_id}
            generation={generation}
            onDelete={handleDelete}
          />
        ))}
      </div>
      </div>
    </div>
  );
}
import { z } from 'zod';

export const API_CONFIG = {
  BASE_URL: 'https://api.instasd.com/api_endpoints/7obbfxqjmmama2',
  API_KEY: 'ka2ngGQRpT0CSr0_75iTIg',
  INPUT_IDS: {
    SEED: 'a6e8e2de8bf354fa',
    PROMPT: 'f2e81bc436e75c2a',
    ALMARAI: '936f115e1d5b06c3',
    NAJDI: '94e30b946d60aaad',
    OMANI_ARCH: 'ca57f79b6fba2a2c',
    WIDTH: '12537dc206e201d4',
    HEIGHT: '063ec2f2f1149337',
    STYLE_1: '9ba0acc16392061c',
    STYLE_2: '8f23038f0d208ada',
    STYLE_3: '0fa8830fe9d5d0b5',
    STYLE_4: '2cdfc3dc2efbb745',
    BATCH_SIZE: '0e8742a207938cbe'
  },
  RETRY: {
    MAX_ATTEMPTS: 3,
    INITIAL_DELAY: 1000,
    BACKOFF_FACTOR: 2,
    MAX_DELAY: 5000
  }
} as const;

export const taskResponseSchema = z.object({
  task_id: z.string(),
  status: z.string(),
  estimated_steps: z.number().optional(),
  completed_steps: z.number().optional(),
  image_urls: z.array(z.string()).optional(),
  video_urls: z.array(z.string()).optional(),
  error: z.string().optional(),
  delay_time: z.number().optional(),
  execution_time: z.number().optional(),
  cost: z.number().optional(),
  seed: z.number().optional(),
  prompt: z.string().optional()
});

export type TaskResponse = z.infer<typeof taskResponseSchema>;
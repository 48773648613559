import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useNavigate } from 'react-router-dom';
import { Sparkles, Loader2 } from 'lucide-react';
import { rmzLogoFull } from '../lib/assets/logo';

const WEBHOOK_URL = 'https://hook.eu2.make.com/fl4fmr57hh673uh44gx8lq8g2fv56lb8';

const PRIMARY_REASONS = [
  'professional use',
  'content creation',
  'Art & Design',
  'research',
  'hobby'
];

export function BetaContactPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  const [formData, setFormData] = useState({
    email: '',
    instagramUsername: '',
    hasUsedAITools: false,
    usedTools: '',
    primaryReason: '',
    hasCreativeExperience: false,
    creativeExperienceDetails: '',
    provideFeedback: false,
    joinDiscord: false,
    agreeToTerms: false,
    beTestimonial: false
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    // Generate a unique access code
    const accessCode = Math.random().toString(36).substring(2, 10).toUpperCase();
    const timestamp = Date.now();

    // Email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    if (!formData.agreeToTerms) {
      setError('You must agree to the platform terms to continue');
      return;
    }

    setLoading(true);

    try {
      // First save to Firestore
      const docRef = await addDoc(collection(db, 'beta-contacts'), {
        ...formData,
        accessCode,
        emailSent: false,
        status: 'pending',
        isApproved: false,
        createdAt: serverTimestamp(),
        timestamp
      });

      // Then send webhook notification
      try {
        const webhookPayload = {
          type: 'new_beta_application',
          email: formData.email,
          instagramUsername: formData.instagramUsername,
          hasUsedAITools: formData.hasUsedAITools,
          usedTools: formData.usedTools || '',
          primaryReason: formData.primaryReason,
          hasCreativeExperience: formData.hasCreativeExperience,
          creativeExperienceDetails: formData.creativeExperienceDetails || '',
          provideFeedback: formData.provideFeedback,
          joinDiscord: formData.joinDiscord,
          beTestimonial: formData.beTestimonial,
          agreeToTerms: formData.agreeToTerms,
          accessCode,
          applicationId: docRef.id,
          timestamp
        };

        const response = await fetch(WEBHOOK_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(webhookPayload)
        });

        if (!response.ok) {
          console.error('Webhook failed:', await response.text());
        }
      } catch (webhookError) {
        console.error('Failed to send webhook:', webhookError);
        // Continue even if webhook fails
      }

      setSuccess(true);
      setTimeout(() => {
        navigate('/beta-success');
      }, 2000);
    } catch (err) {
      console.error('Error submitting form:', err);
      setError('Failed to submit application. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a0b2e] via-[#1a0b2e] to-[#0f1955] text-white">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[300px] h-[300px] bg-[#FF3B8B] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#00F5D4] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[300px] h-[300px] bg-[#B86EFF] rounded-full filter blur-[150px] opacity-20" />
      </div>

      <div className="relative container mx-auto px-4 py-8">
        <img 
          src={rmzLogoFull} 
          alt="RMZ.AI" 
          className="h-16 w-auto mb-12 cursor-pointer" 
          onClick={() => navigate('/')}
        />
        
        <div className="max-w-2xl mx-auto">
          <div className="flex items-center gap-4 mb-8">
            <Sparkles className="w-8 h-8 text-[#00F5D4] animate-pulse" />
            <div>
              <h1 className="text-2xl font-bold bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-transparent bg-clip-text">Join Our Beta Program</h1>
              <p className="text-gray-400">Be among the first to experience our AI image generation platform</p>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              {/* Email Address */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">What is your email address?</label>
                <input
                  type="email"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  required
                  value={formData.email}
                  onChange={e => {
                    const value = e.target.value;
                    setFormData(prev => ({ ...prev, email: value }));
                    // Clear error when user starts typing a valid email
                    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                      setError('');
                    }
                  }}
                  onInvalid={(e: React.InvalidEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    setError('Please enter a valid email address');
                  }}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                  placeholder="Enter your email address"
                  title="Please enter a valid email address"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">Instagram Username <span className="text-[#FF3B8B]">*</span></label>
                <div className="relative">
                  <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400">@</span>
                  <input
                    type="text"
                    required
                    value={formData.instagramUsername}
                    onChange={e => setFormData(prev => ({ ...prev, instagramUsername: e.target.value.replace('@', '') }))}
                    className="w-full pl-8 pr-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                    placeholder="Enter your Instagram username"
                  />
                </div>
              </div>

              {/* AI Tools Experience */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">Have you used AI-generated image tools before?</label>
                <div className="flex gap-4">
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="radio"
                      checked={formData.hasUsedAITools}
                      onChange={() => setFormData(prev => ({ ...prev, hasUsedAITools: true }))}
                      className="w-4 h-4 text-[#B86EFF] focus:ring-[#B86EFF]"
                    />
                    <span className="text-gray-300">Yes</span>
                  </label>
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="radio"
                      checked={!formData.hasUsedAITools}
                      onChange={() => setFormData(prev => ({ ...prev, hasUsedAITools: false }))}
                      className="w-4 h-4 text-[#B86EFF] focus:ring-[#B86EFF]"
                    />
                    <span className="text-gray-300">No</span>
                  </label>
                </div>
              </div>

              {/* Used Tools */}
              {formData.hasUsedAITools && (
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">If yes, which ones?</label>
                  <input
                    type="text"
                    value={formData.usedTools}
                    onChange={e => setFormData(prev => ({ ...prev, usedTools: e.target.value }))}
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                    placeholder="e.g., Midjourney, DALL-E, Stable Diffusion"
                  />
                </div>
              )}

              {/* Primary Reason */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">What is your primary reason for wanting to test our platform?</label>
                <select
                  required
                  value={formData.primaryReason}
                  onChange={e => setFormData(prev => ({ ...prev, primaryReason: e.target.value }))}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white"
                >
                  <option value="" className="bg-[#1a0b2e]">Choose one</option>
                  {PRIMARY_REASONS.map(reason => (
                    <option key={reason} value={reason} className="bg-[#1a0b2e]">{reason}</option>
                  ))}
                </select>
              </div>

              {/* Creative Experience */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">Do you have experience in digital art, graphic design, or creative fields?</label>
                <div className="flex gap-4">
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="radio"
                      checked={formData.hasCreativeExperience}
                      onChange={() => setFormData(prev => ({ ...prev, hasCreativeExperience: true }))}
                      className="w-4 h-4 text-[#B86EFF] focus:ring-[#B86EFF]"
                    />
                    <span className="text-gray-300">Yes</span>
                  </label>
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="radio"
                      checked={!formData.hasCreativeExperience}
                      onChange={() => setFormData(prev => ({ ...prev, hasCreativeExperience: false }))}
                      className="w-4 h-4 text-[#B86EFF] focus:ring-[#B86EFF]"
                    />
                    <span className="text-gray-300">No</span>
                  </label>
                </div>
              </div>

              {/* Creative Experience Details */}
              {formData.hasCreativeExperience && (
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">If yes, please describe briefly:</label>
                  <textarea
                    value={formData.creativeExperienceDetails}
                    onChange={e => setFormData(prev => ({ ...prev, creativeExperienceDetails: e.target.value }))}
                    rows={4}
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00F5D4] text-white resize-none"
                    placeholder="Tell us about your experience..."
                  />
                </div>
              )}

              {/* Additional Questions */}
              <div className="space-y-3">
                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.provideFeedback}
                    onChange={e => setFormData(prev => ({ ...prev, provideFeedback: e.target.checked }))}
                    className="w-4 h-4 rounded border-gray-300 text-[#B86EFF] focus:ring-[#B86EFF]"
                  />
                  <span className="text-sm text-gray-300">Are you comfortable providing feedback and reporting bugs?</span>
                </label>

                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.joinDiscord}
                    onChange={e => setFormData(prev => ({ ...prev, joinDiscord: e.target.checked }))}
                    className="w-4 h-4 rounded border-gray-300 text-[#B86EFF] focus:ring-[#B86EFF]"
                  />
                  <span className="text-sm text-gray-300">Are you open to participating in a private tester community (Discord)?</span>
                </label>

                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.agreeToTerms}
                    onChange={e => setFormData(prev => ({ ...prev, agreeToTerms: e.target.checked }))}
                    className="w-4 h-4 rounded border-gray-300 text-[#B86EFF] focus:ring-[#B86EFF]"
                  />
                  <span className="text-sm text-gray-300">Do you agree not to use the platform for harmful, unethical, or illegal content?</span>
                </label>

                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.beTestimonial}
                    onChange={e => setFormData(prev => ({ ...prev, beTestimonial: e.target.checked }))}
                    className="w-4 h-4 rounded border-gray-300 text-[#B86EFF] focus:ring-[#B86EFF]"
                  />
                  <span className="text-sm text-gray-300">Would you be interested in being featured as an early user or giving a testimonial?</span>
                </label>
              </div>
            </div>

            {error && (
              <div className="text-red-400 text-sm">{error}</div>
            )}

            {success && (
              <div className="text-green-400 text-sm">Application submitted successfully! Redirecting...</div>
            )}

            <button
              type="submit"
              disabled={loading || success}
              className="w-full px-8 py-3 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] rounded-full text-white font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {loading ? (
                <div className="flex items-center gap-2">
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Submitting...
                </div>
              ) : success ? (
                'Submitted!'
              ) : (
                'Submit Application'
              )}
            </button>

            <p className="text-sm text-gray-400 text-center">
              Your information will be kept confidential and used only for beta testing purposes.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
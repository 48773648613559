import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  MessageSquare, 
  Hash, 
  Wand2, 
  Palette, 
  Copy,
  ArrowUpCircle,
  Download,
  Trash2,
  Loader2,
  AlertCircle,
  ChevronDown,
  ChevronUp,
  ArrowUpRight,
  Share2,
  Check
} from 'lucide-react';
import type { TaskStatus } from '../types';
import { downloadImage } from '../lib/firebase/download';
import { shareToGallery, isImageShared, unshareFromGallery, deleteGenerationImage } from '../lib/firebase/db';
import { useAuth } from '../contexts/AuthContext';

interface GenerationCardProps {
  generation: TaskStatus;
  onDelete?: (generationId: string, imageUrl: string, allImageUrls: string[]) => void;
}

export function GenerationCard({ generation, onDelete }: GenerationCardProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [downloading, setDownloading] = useState<string | null>(null);
  const [sharing, setSharing] = useState<string | null>(null);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  const [sharedStatus, setSharedStatus] = useState<Record<string, string | null>>({});
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);

  const isUpscaled = generation.metadata?.type === 'upscale';

  // Get Firebase URLs for each image
  const getFirebaseUrl = (originalUrl: string, index: number) => {
    if (isUpscaled) return originalUrl;
    return generation.firebaseImageUrls?.[index] || originalUrl;
  };

  useEffect(() => {
    let mounted = true;

    async function checkSharedStatus() {
      if (!user || !generation.image_urls) return;

      try {
        setIsLoadingStatus(true);
        const statusPromises = generation.image_urls.map(async (url, index) => {
          const firebaseUrl = getFirebaseUrl(url, index);
          return isImageShared(user.uid, generation.task_id, firebaseUrl);
        });
        
        const statuses = await Promise.all(statusPromises);
        
        if (mounted) {
          const statusMap = generation.image_urls.reduce((acc, url, index) => {
            acc[url] = statuses[index];
            return acc;
          }, {} as Record<string, string | null>);
          
          setSharedStatus(statusMap);
        }
      } catch (error) {
        console.error('Error checking shared status:', error);
      } finally {
        if (mounted) {
          setIsLoadingStatus(false);
        }
      }
    }

    checkSharedStatus();

    return () => {
      mounted = false;
    };
  }, [user, generation.task_id, generation.image_urls, generation.firebaseImageUrls, isUpscaled]);

  const handleShare = async (imageUrl: string) => {
    if (sharing || !user || !imageUrl) return;
    
    try {
      setSharing(imageUrl);
      setError(null);

      const index = generation.image_urls.indexOf(imageUrl);
      const firebaseUrl = getFirebaseUrl(imageUrl, index);

      if (sharedStatus[imageUrl]) {
        await unshareFromGallery(sharedStatus[imageUrl]!);
        setSharedStatus(prev => ({ ...prev, [imageUrl]: null }));
      } else {
        await shareToGallery(user.uid, generation, firebaseUrl);
        // Immediately check the new shared status
        const newSharedId = await isImageShared(user.uid, generation.task_id, firebaseUrl);
        setSharedStatus(prev => ({ ...prev, [imageUrl]: newSharedId }));
      }
    } catch (err) {
      console.error('Error sharing/unsharing to gallery:', err);
      const message = err instanceof Error ? err.message : 'Failed to share to community gallery';
      setError(message);
    } finally {
      setSharing(null);
    }
  };

  const handleDownload = async (imageUrl: string, prompt: string) => {
    if (downloading) return;
    
    setDownloading(imageUrl);
    setError(null);
    document.body.style.cursor = 'wait';

    try {
      const filename = prompt
        ? `rmz-ai-${prompt.slice(0, 30).replace(/[^a-z0-9]/gi, '-')}.png`
        : `rmz-ai-generation-${Date.now()}.png`;

      const index = generation.image_urls.indexOf(imageUrl);
      const firebaseUrl = getFirebaseUrl(imageUrl, index);
      
      await downloadImage(firebaseUrl, {
        filename,
        fallbackUrl: imageUrl
      });
    } catch (err) {
      console.error('Error downloading image:', err);
      setError('Failed to download image. Click to try again.');
    } finally {
      setDownloading(null);
      document.body.style.cursor = 'default';
    }
  };

  const handleDelete = async (imageUrl: string) => {
    if (deleting || !onDelete) return;
    
    if (!confirm('Are you sure you want to delete this image? This action cannot be undone.')) {
      return;
    }

    setDeleting(imageUrl);
    setError(null);

    try {
      const index = generation.image_urls.indexOf(imageUrl);
      const firebaseUrl = getFirebaseUrl(imageUrl, index);
      
      await deleteGenerationImage(generation.task_id, firebaseUrl, generation.image_urls);
      onDelete(generation.task_id, imageUrl, generation.image_urls);
    } catch (err) {
      console.error('Error deleting image:', err);
      setError('Failed to delete image. Please try again.');
    } finally {
      setDeleting(null);
    }
  };

  const handleUpscale = async (imageUrl: string, prompt: string) => {
    try {
      setError(null);

      const upscaleData = {
        imageUrl,
        prompt,
        timestamp: Date.now()
      };
      
      sessionStorage.setItem('upscale_image', JSON.stringify(upscaleData));
      navigate('/upscale');
    } catch (err) {
      console.error('Upscale prep error:', err);
      const message = err instanceof Error ? err.message : 'Failed to prepare image';
      setError(message);
    }
  };

  const ImageActions = ({ imageUrl, index }: { imageUrl: string; index: number }) => (
    <div className="absolute bottom-2 left-2 right-2 flex justify-center gap-2">
      <button
        onClick={() => navigator.clipboard.writeText(generation.prompt || '')}
        className="p-1.5 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
        title="Copy Prompt"
      >
        <Copy className="w-4 h-4" />
      </button>
      {!isUpscaled && (
        <button
          onClick={() => handleUpscale(imageUrl, generation.prompt || '')}
          className="p-1.5 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
          title="Upscale Image"
        >
          <ArrowUpCircle className="w-4 h-4" />
        </button>
      )}
      <button
        onClick={() => handleShare(imageUrl)}
        disabled={sharing === imageUrl || isLoadingStatus}
        className={`p-1.5 rounded-lg ${
          sharedStatus[imageUrl]
            ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30'
            : 'bg-white/10 hover:bg-white/20 text-white'
        } transition-colors disabled:opacity-50 disabled:cursor-wait`}
        title={sharedStatus[imageUrl] ? "Remove from Community Gallery" : "Share to Community Gallery"}
      >
        {sharing === imageUrl ? (
          <Loader2 className="w-4 h-4 animate-spin" />
        ) : sharedStatus[imageUrl] ? (
          <Check className="w-4 h-4" />
        ) : (
          <Share2 className="w-4 h-4" />
        )}
      </button>
      <button
        onClick={() => handleDownload(imageUrl, generation.prompt || '')}
        disabled={downloading === imageUrl}
        className="p-1.5 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors disabled:opacity-50 disabled:cursor-wait"
        title="Download Image"
      >
        {downloading === imageUrl ? (
          <Loader2 className="w-4 h-4 animate-spin" />
        ) : (
          <Download className="w-4 h-4" />
        )}
      </button>
      <button
        onClick={() => handleDelete(imageUrl)}
        disabled={deleting === imageUrl}
        className="p-1.5 rounded-lg bg-red-500/20 hover:bg-red-500/30 text-white transition-colors disabled:opacity-50 disabled:cursor-wait"
        title="Delete Image"
      >
        {deleting === imageUrl ? (
          <Loader2 className="w-4 h-4 animate-spin" />
        ) : (
          <Trash2 className="w-4 h-4" />
        )}
      </button>
    </div>
  );

  return (
    <div className={`bg-white dark:bg-rmz-gray rounded-xl overflow-hidden border transition-colors ${
      isUpscaled 
        ? 'border-rmz-secondary dark:border-rmz-secondary/50' 
        : 'border-gray-200 dark:border-rmz-gray-light'
    }`}>
      {error && (
        <div className="p-3 bg-red-50 dark:bg-red-900/20 border-b border-red-200 dark:border-red-800 text-red-600 dark:text-red-400 text-sm flex items-center gap-2">
          <AlertCircle className="w-4 h-4" />
          <span>{error}</span>
        </div>
      )}

      <div className={`p-4 ${generation.image_urls.length === 1 ? '' : 'grid grid-cols-2 gap-2'}`}>
        {generation.image_urls.map((imageUrl, index) => (
          <div 
            key={`${generation.task_id}-${index}`} 
            className={`relative ${generation.image_urls.length === 1 ? 'aspect-square' : 'aspect-square'}`}
          >
            {isUpscaled && (
              <div className="absolute top-2 left-2 px-2 py-1 bg-rmz-secondary/90 text-black rounded-lg text-xs font-medium flex items-center gap-1">
                <ArrowUpRight className="w-3 h-3" />
                Upscaled
              </div>
            )}
            {!isLoadingStatus && sharedStatus[imageUrl] && (
              <div className="absolute top-2 right-2 px-2 py-1 bg-green-500/90 text-white rounded-lg text-xs font-medium">
                Shared to Community
              </div>
            )}
            <img
              src={imageUrl}
              alt={`${generation.prompt} (${index + 1}/${generation.image_urls.length})`}
              className="w-full h-full object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black/50 opacity-0 hover:opacity-100 transition-opacity rounded-lg">
              <ImageActions imageUrl={imageUrl} index={index} />
            </div>
          </div>
        ))}
      </div>

      <div className="px-4 pb-2">
        <div className="flex items-start gap-2 mb-2">
          <MessageSquare className="w-4 h-4 mt-1 flex-shrink-0 text-gray-500 dark:text-gray-400" />
          <p className="text-sm text-gray-700 dark:text-gray-300 line-clamp-2">
            {generation.prompt}
          </p>
        </div>

        <div className="flex items-center justify-between text-xs text-gray-600 dark:text-gray-400">
          {generation.seed && !isUpscaled && (
            <div className="flex items-center gap-1">
              <Hash className="w-3.5 h-3.5" />
              <span>{generation.seed}</span>
            </div>
          )}
          {isUpscaled && generation.metadata?.originalImage && (
            <div className="flex items-center gap-1">
              <ArrowUpCircle className="w-3.5 h-3.5" />
              <span>Upscaled version</span>
            </div>
          )}

          <button
            onClick={() => setShowDetails(!showDetails)}
            className="flex items-center gap-1 text-rmz-primary hover:text-rmz-primary/80 transition-colors"
          >
            <span>{showDetails ? 'Less' : 'More'}</span>
            {showDetails ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
          </button>
        </div>
      </div>

      {showDetails && !isUpscaled && (
        <div className="px-4 pb-4 space-y-4 border-t border-gray-200 dark:border-rmz-gray-light mt-2 pt-4">
          {generation.loraModels?.some(model => model.value > 0) && (
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-xs font-medium text-gray-700 dark:text-gray-300">
                <Wand2 className="w-3.5 h-3.5" />
                <span>Active Models</span>
              </div>
              <div className="grid grid-cols-2 gap-2">
                {generation.loraModels
                  .filter(model => model.value > 0)
                  .map(model => (
                    <div 
                      key={model.id}
                      className="text-xs bg-rmz-primary/10 text-rmz-primary rounded-lg px-2 py-1 flex justify-between items-center"
                    >
                      <span>{model.title}</span>
                      <span>{model.value.toFixed(1)}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          )}

          {generation.styles && Object.values(generation.styles).some(style => style !== 'No Style') && (
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-xs font-medium text-gray-700 dark:text-gray-300">
                <Palette className="w-3.5 h-3.5" />
                <span>Active Styles</span>
              </div>
              <div className="grid grid-cols-2 gap-2">
                {Object.entries(generation.styles)
                  .filter(([_, value]) => value !== 'No Style')
                  .map(([key, value]) => (
                    <div 
                      key={key}
                      className="text-xs bg-rmz-primary/10 text-rmz-primary rounded-lg px-2 py-1 text-center"
                    >
                      {value}
                    </div>
                  ))
                }
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
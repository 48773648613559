import React, { useState } from 'react';
import { Wand2, Loader2, Sparkles, Dice6, Lock, Unlock } from 'lucide-react';
import { LoraCategory } from './LoraCategory';
import { BatchSizeSelector } from './BatchSizeSelector';
import { AspectRatioSelector } from './AspectRatioSelector';
import { StyleSelector } from './StyleSelector';
import { enhancePrompt } from '../lib/services/prompt-enhancer';
import type { LoraModel, StyleOptions } from '../lib/types';
import type { EnhancementSettings } from '../lib/types/enhancement';
import { ASPECT_RATIOS, type AspectRatio } from '../lib/constants';

interface GenerationFormProps {
  prompt: string;
  setPrompt: (prompt: string) => void;
  seed: number;
  setSeed: (seed: number) => void;
  aspectRatio: AspectRatio;
  setAspectRatio: (ratio: AspectRatio) => void;
  batchSize: number;
  setBatchSize: (size: number) => void;
  loraModels: LoraModel[];
  setLoraModels: (models: LoraModel[]) => void;
  styles: StyleOptions;
  setStyles: (styles: StyleOptions) => void;
  loading: boolean;
  error: string;
  onGenerate: () => void;
}

export function GenerationForm({
  prompt,
  setPrompt,
  seed,
  setSeed,
  aspectRatio,
  setAspectRatio,
  batchSize,
  setBatchSize,
  loraModels,
  setLoraModels,
  styles,
  setStyles,
  loading,
  error,
  onGenerate,
}: GenerationFormProps) {
  const [isFixedSeed, setIsFixedSeed] = React.useState(false);
  const [enhancing, setEnhancing] = useState(false);
  const [enhancementError, setEnhancementError] = useState('');

  const generateRandomSeed = () => {
    setSeed(Math.floor(Math.random() * 2147483647));
  };

  const handleLoraChange = (categoryModels: LoraModel[]) => {
    const updatedModels = loraModels.map(model => {
      const updatedModel = categoryModels.find(m => m.id === model.id);
      return updatedModel || model;
    });
    setLoraModels(updatedModels);
  };

  const handleEnhancePrompt = async () => {
    if (!prompt.trim()) {
      setEnhancementError('Please enter a prompt to enhance');
      return;
    }

    setEnhancing(true);
    setEnhancementError('');

    try {
      const settings: EnhancementSettings = {
        creativity: 0.7,
        maxLength: 200
      };

      const result = await enhancePrompt(prompt, settings);
      if (result.enhancedPrompt) {
        setPrompt(result.enhancedPrompt);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to enhance prompt';
      setEnhancementError(errorMessage);
      console.error('Enhancement error:', error);
    } finally {
      setEnhancing(false);
    }
  };

  const handleGenerate = () => {
    if (!isFixedSeed) {
      generateRandomSeed();
    }
    onGenerate();
  };

  const characterModels = loraModels.filter(model => 
    ['omani', 'saudi'].includes(model.id)
  );

  const locationModels = loraModels.filter(model => 
    ['omani-arch', 'najdi'].includes(model.id)
  );

  // Keep the product models filter for future use
  const productModels = loraModels.filter(model => 
    [].includes(model.id) // Empty array until new product models are added
  );

  return (
    <div className="space-y-6">
      <div className="relative">
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className="w-full h-32 px-4 py-3 bg-white dark:bg-rmz-gray border border-gray-200 dark:border-rmz-gray-light rounded-xl text-gray-900 dark:text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-rmz-primary/50 resize-none transition-colors"
          placeholder="Describe your imagination..."
        />
        <div className="mt-4 flex gap-4">
          <button
            onClick={handleEnhancePrompt}
            disabled={enhancing || !prompt.trim()}
            className="flex-1 px-4 py-3 rounded-xl bg-white dark:bg-rmz-gray border border-gray-200 dark:border-rmz-gray-light text-gray-900 dark:text-white font-medium flex items-center justify-center gap-2 hover:bg-gray-50 dark:hover:bg-rmz-gray-light transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {enhancing ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <Sparkles className="w-5 h-5" />
            )}
            {enhancing ? 'Enhancing...' : 'Enhance Prompt'}
          </button>
          <button
            onClick={handleGenerate}
            disabled={loading || !prompt.trim()}
            className="flex-1 px-4 py-3 rounded-xl bg-gradient-primary text-rmz-dark font-medium flex items-center justify-center gap-2 hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <Wand2 className="w-5 h-5" />
            )}
            {loading ? 'Generating...' : 'Generate'}
          </button>
        </div>
      </div>

      {(error || enhancementError) && (
        <div className="text-red-500 dark:text-red-400 text-sm">{error || enhancementError}</div>
      )}

      <div className="bg-white dark:bg-rmz-gray rounded-xl p-6 border border-gray-200 dark:border-rmz-gray-light transition-colors space-y-8">
        <BatchSizeSelector
          value={batchSize}
          onChange={setBatchSize}
        />

        <AspectRatioSelector
          value={aspectRatio}
          onChange={setAspectRatio}
        />

        <LoraCategory
          title="Character Boost"
          models={characterModels}
          onChange={(models) => handleLoraChange(models)}
        />

        <LoraCategory
          title="Location Boost"
          models={locationModels}
          onChange={(models) => handleLoraChange(models)}
        />

        <LoraCategory
          title="Product Boost"
          models={[]}
          onChange={(models) => handleLoraChange(models)}
        />

        <StyleSelector
          styles={styles}
          onChange={setStyles}
        />

        <div className="pt-4 border-t border-gray-200 dark:border-rmz-gray-light">
          <div className="flex items-center justify-between mb-2">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-300">Seed</label>
            <div className="flex gap-2">
              <button
                onClick={() => setIsFixedSeed(!isFixedSeed)}
                className={`p-2 rounded-lg transition-colors ${
                  isFixedSeed 
                    ? 'text-rmz-primary bg-rmz-primary/10' 
                    : 'text-gray-400 hover:text-rmz-primary'
                }`}
                title={isFixedSeed ? "Fixed seed" : "Random seed"}
              >
                {isFixedSeed ? <Lock className="w-4 h-4" /> : <Unlock className="w-4 h-4" />}
              </button>
              <button
                onClick={generateRandomSeed}
                className="text-rmz-primary hover:text-rmz-primary/80 transition-colors p-2 rounded-lg"
                title="Generate random seed"
                disabled={!isFixedSeed}
              >
                <Dice6 className="w-4 h-4" />
              </button>
            </div>
          </div>
          <input
            type="number"
            value={seed}
            onChange={(e) => setSeed(parseInt(e.target.value) || 0)}
            className={`w-full px-4 py-2 bg-gray-50 dark:bg-rmz-gray-light rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-rmz-primary/50 transition-colors ${
              !isFixedSeed ? 'opacity-50' : ''
            }`}
            disabled={!isFixedSeed}
          />
        </div>
      </div>
    </div>
  );
}
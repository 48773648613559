import { useNavigate } from 'react-router-dom';
import { CheckCircle, Home, Sparkles } from 'lucide-react';
import { rmzLogoFull } from '../lib/assets/logo';

export function BetaSuccessPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a0b2e] via-[#1a0b2e] to-[#0f1955] text-white">
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[300px] h-[300px] bg-[#FF3B8B] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#00F5D4] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[300px] h-[300px] bg-[#B86EFF] rounded-full filter blur-[150px] opacity-20" />
      </div>

      <div className="relative container mx-auto px-4 py-8">
        <img 
          src={rmzLogoFull} 
          alt="RMZ.AI" 
          className="h-16 w-auto mb-12 cursor-pointer" 
          onClick={() => navigate('/')}
        />
        
        <div className="max-w-2xl mx-auto text-center">
          {/* Success Animation */}
          <div className="mb-8 relative">
            <div className="absolute inset-0 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] rounded-full opacity-20 animate-pulse blur-xl" />
            <div className="relative bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] rounded-full p-1 inline-block">
              <div className="bg-[#1a0b2e] rounded-full p-4">
                <CheckCircle className="w-16 h-16 text-[#00F5D4]" />
              </div>
            </div>
          </div>

          {/* Success Message */}
          <h1 className="text-3xl font-bold bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-transparent bg-clip-text mb-4">
            Application Submitted Successfully!
          </h1>
          
          <div className="space-y-6 mb-12">
            <p className="text-xl text-gray-300">
              Thank you for your interest in joining our beta program.
            </p>
            
            <div className="bg-white/5 backdrop-blur-lg rounded-2xl p-8 border border-white/10">
              <div className="flex items-center gap-3 mb-4">
                <Sparkles className="w-5 h-5 text-[#00F5D4]" />
                <h2 className="text-lg font-medium">What's Next?</h2>
              </div>
              
              <ul className="text-left space-y-4 text-gray-300">
                <li className="flex items-start gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#00F5D4] mt-2 flex-shrink-0" />
                  <span>Our team will review your application within the next 48 hours</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#00F5D4] mt-2 flex-shrink-0" />
                  <span>You'll receive an email with further instructions and access details</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#00F5D4] mt-2 flex-shrink-0" />
                  <span>Make sure to check your spam folder if you don't see our email</span>
                </li>
              </ul>
            </div>
          </div>

          {/* Return Home Button */}
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center gap-2 px-8 py-3 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] rounded-full text-white font-medium hover:opacity-90 transition-opacity group"
          >
            <Home className="w-5 h-5" />
            <span>Return to Home</span>
          </button>
        </div>
      </div>
    </div>
  );
}
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../config';
import type { User } from 'firebase/auth';

export interface UserProfile {
  uid: string;
  email: string;
  displayName: string;
  transaction_type: string | null;
  planStatus: string;
  planType: string;
  credits: number;
  createdAt: Date;
  lastLoginAt: Date;
}

export async function createOrUpdateUser(user: User): Promise<void> {
  if (!user.email) {
    console.warn('User has no email');
    return;
  }

  try {
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName || user.email.split('@')[0],
      transaction_type: null,
      planStatus: 'not active',
      planType: 'free',
      credits: 100,
      lastLoginAt: new Date()
    };

    if (!userDoc.exists()) {
      // Create new user profile
      await setDoc(userRef, {
        ...userData,
        createdAt: new Date()
      });
    } else {
      // Update last login
      await setDoc(userRef, userData, { merge: true });
    }
  } catch (error) {
    console.error('Error saving user data:', error);
    throw new Error('Failed to save user data');
  }
}

export async function getUserProfile(uid: string): Promise<UserProfile | null> {
  try {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      return null;
    }

    return userDoc.data() as UserProfile;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }
}
import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useNavigate } from 'react-router-dom';
import { useGeneratedImages } from '../lib/hooks/useGeneratedImages';
import { Loader2 } from 'lucide-react';

const CONTACT_REASONS = [
  'Beta Testing Access',
  'Business Inquiry',
  'Technical Support',
  'Feature Request',
  'Other'
];

export function ContactPage() {
  const navigate = useNavigate();
  const { images, loading: imagesLoading } = useGeneratedImages(6);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    reason: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.fullName || !formData.email || !formData.reason || !formData.message) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await addDoc(collection(db, 'contacts'), {
        fullName: formData.fullName,
        email: formData.email,
        reason: formData.reason,
        message: formData.message,
        createdAt: new Date()
      });
      
      setSuccess(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      console.error('Error submitting form:', err);
      setError('Failed to submit form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="min-h-screen pt-32 bg-gradient-to-b from-[#1a0b2e] via-[#1a0b2e] to-[#0f1955] text-white">
      <div className="grid grid-cols-1 lg:grid-cols-2 min-h-screen">
        {/* Gallery Grid */}
        <div className="hidden lg:block relative">
          <div className="grid grid-cols-2 gap-4 p-8">
            {imagesLoading ? (
              <div className="col-span-2 h-full flex items-center justify-center">
                <Loader2 className="w-8 h-8 animate-spin text-purple-500" />
              </div>
            ) : (
              images.map((image, index) => (
                <div 
                  key={index}
                  className="aspect-square rounded-2xl overflow-hidden group relative"
                >
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-pink-500 via-purple-500 to-cyan-500 rounded-2xl opacity-75 group-hover:opacity-100 transition-opacity"></div>
                  <div className="relative h-full rounded-2xl overflow-hidden">
                    <img
                      src={image}
                      alt={`Generated ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="absolute inset-0 bg-gradient-to-r from-transparent to-[#1a0b2e]/90" />
        </div>

        {/* Contact Form */}
        <div className="p-8 lg:p-16 flex flex-col justify-center relative">
          <div className="max-w-lg">
            <h1 className="text-4xl font-bold text-white mb-8">Need to contact us?</h1>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-gray-300 mb-2">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  required
                  value={formData.fullName}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg bg-gray-900/50 border border-gray-700 text-white focus:outline-none focus:border-purple-500 transition-colors"
                  placeholder="Enter your full name"
                />
              </div>

              <div>
                <label className="block text-gray-300 mb-2">Reason For Contact</label>
                <select
                  name="reason"
                  required
                  value={formData.reason}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg bg-gray-900/50 border border-gray-700 text-white focus:outline-none focus:border-purple-500 transition-colors"
                >
                  <option value="">Select a choice</option>
                  {CONTACT_REASONS.map(reason => (
                    <option key={reason} value={reason}>{reason}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-gray-300 mb-2">Contact Email</label>
                <input
                  type="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg bg-gray-900/50 border border-gray-700 text-white focus:outline-none focus:border-purple-500 transition-colors"
                  placeholder="example@email.com"
                />
              </div>

              <div>
                <label className="block text-gray-300 mb-2">Message</label>
                <textarea
                  name="message"
                  required
                  value={formData.message}
                  onChange={handleChange}
                  rows={6}
                  className="w-full px-4 py-3 rounded-lg bg-gray-900/50 border border-gray-700 text-white focus:outline-none focus:border-purple-500 transition-colors resize-none"
                  placeholder="Your Message"
                />
              </div>

              {error && (
                <div className="text-red-400 text-sm">{error}</div>
              )}

              {success && (
                <div className="text-green-400 text-sm">Message sent successfully! Redirecting...</div>
              )}

              <button
                type="submit"
                disabled={loading || success}
                className="w-full px-8 py-3 text-lg font-medium rounded-lg bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:opacity-90 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Sending...' : success ? 'Sent!' : 'Send Message'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
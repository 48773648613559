import { AspectRatio } from '../lib/constants';
import { Square, MonitorPlay, RectangleVertical } from 'lucide-react';

interface AspectRatioSelectorProps {
  value: AspectRatio;
  onChange: (ratio: AspectRatio) => void;
}

export function AspectRatioSelector({ value, onChange }: AspectRatioSelectorProps) {
  const options = [
    { ratio: '1:1' as AspectRatio, icon: Square, label: 'Square' },
    { ratio: '16:9' as AspectRatio, icon: MonitorPlay, label: 'HD' },
    { ratio: '9:16' as AspectRatio, icon: RectangleVertical, label: 'Vertical' },
  ];

  return (
    <div className="space-y-2">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white">Aspect Ratio</h3>
      <div className="grid grid-cols-3 gap-2">
        {options.map(({ ratio, icon: Icon, label }) => (
          <button
            key={ratio}
            onClick={() => onChange(ratio)}
            className={`p-3 rounded-lg flex flex-col items-center gap-1 transition-colors ${
              value === ratio
                ? 'bg-rmz-primary/20 text-rmz-primary'
                : 'bg-gray-100 dark:bg-rmz-gray-light text-gray-600 dark:text-gray-400 hover:text-rmz-primary dark:hover:text-rmz-primary'
            }`}
            title={label}
          >
            <Icon className="w-5 h-5" />
            <span className="text-xs">{ratio}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
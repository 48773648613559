import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCBkOQy1EGhyt9m5nl_q4hYiEzrKkzU0Ak",
  authDomain: "rmz-ai-4d647.firebaseapp.com",
  projectId: "rmz-ai-4d647",
  storageBucket: "rmz-ai-4d647.firebasestorage.app",
  messagingSenderId: "369912340449",
  appId: "1:369912340449:web:accd43bb68e6c27a5f3ca0",
  measurementId: "G-FD9NZM9GH3"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Initialize analytics conditionally
let analytics = null;
isSupported().then(yes => {
  if (yes) {
    analytics = getAnalytics(app);
  }
});

export { analytics };

// Initialize Firestore indexes
const INDEXES = [
  {
    collectionGroup: 'generations',
    queryScope: 'COLLECTION',
    fields: [
      { fieldPath: 'userId', order: 'ASCENDING' },
      { fieldPath: 'createdAt', order: 'DESCENDING' }
    ]
  }
];
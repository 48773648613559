import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Instagram, Home, Grid, LogIn, MessageSquare, Users2, ImagePlus, Palette, ArrowUpCircle, Sun, Moon, LogOut } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { rmzLogo } from '../../lib/assets/logo';
import { Dock, DockIcon, DockItem, DockLabel } from '../ui/dock';

interface NavItem {
  label: string;
  path?: string;
  icon: React.ElementType;
  onClick?: () => void;
  external?: boolean;
  requiresAuth?: boolean;
  badge?: string;
}

const loggedOutItems: NavItem[] = [
  {
    label: 'Discord',
    icon: Users2,
    onClick: () => window.open('https://discord.gg/efPbhtte', '_blank')
  },
  {
    label: 'Contact',
    icon: MessageSquare,
    path: '/contact'
  },
  {
    label: 'Instagram',
    icon: Instagram,
    onClick: () => window.open('https://instagram.com/rmz.ai', '_blank')
  }
];

const loggedInItems: NavItem[] = [
  {
    label: 'Create',
    path: '/create',
    icon: ImagePlus,
    requiresAuth: true
  },
  {
    label: 'Canvas',
    path: '/canvas',
    icon: Palette,
    requiresAuth: true,
    badge: 'Soon'
  },
  {
    label: 'Upscale',
    path: '/upscale',
    icon: ArrowUpCircle,
    requiresAuth: true
  },
  {
    label: 'My Gallery',
    path: '/my-gallery',
    icon: Grid,
    requiresAuth: true
  }
];

export function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signOut } = useAuth();
  const { theme, toggleTheme } = useTheme();

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  // Common items that always show
  const commonItems: NavItem[] = [
    {
      label: 'RMZ.AI',
      path: '/',
      icon: () => (
        <div className="w-[120px] h-[40px] flex items-center justify-center">
          <img 
            src={rmzLogo}
            alt="RMZ.AI"
            className="w-full h-full object-contain"
          />
        </div>
      ),
    },
    {
      label: 'Community',
      path: '/gallery',
      icon: Grid
    }
  ];

  // Combine items based on auth status
  const visibleItems = [
    ...commonItems,
    ...(user ? loggedInItems : loggedOutItems)
  ];

  return (
    <header className="fixed top-4 left-0 right-0 z-50 h-20 bg-transparent">
      
      <div className="container mx-auto h-full flex items-center justify-center">
        <Dock 
          className="items-center" 
          magnification={84} 
          distance={120} 
          panelHeight={56}
          spring={{ mass: 0.6, stiffness: 80, damping: 20 }}
        >
          <AnimatePresence mode="wait">
            {visibleItems.map((item, index) => {
              const Icon = item.icon;
              const isActive = item.path && location.pathname === item.path;
              
              return (
                <motion.div
                  key={item.label}
                  initial={{ opacity: 0, scale: 0.8, y: 20 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.8, y: 20 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                >
                  <DockItem
                    className={`aspect-square rounded-lg transition-colors ${typeof Icon === 'function' ? '' : 
                      isActive ? 'bg-rmz-primary/20' : ''
                    }`}
                  >
                    <DockLabel>{item.label}</DockLabel>
                    <DockIcon>
                      <div 
                        className="relative w-full h-full flex items-center justify-center"
                        onClick={item.onClick || (item.path ? () => navigate(item.path) : undefined)}
                      >
                        <div>
                          {typeof Icon === 'function' ? (
                            <Icon />
                          ) : (
                            <Icon className={`w-6 h-6 ${
                            isActive ? 'text-rmz-primary' : 'text-gray-600 dark:text-gray-400'
                            }`} />
                          )}
                        </div>
                        {item.badge && (
                          <span className="absolute -top-1 -right-1 px-2 py-1 text-xs font-bold rounded-full bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-white">
                            {item.badge}
                          </span>
                        )}
                      </div>
                    </DockIcon>
                  </DockItem>
                </motion.div>
              );
            })}
          </AnimatePresence>

          {/* Theme Toggle */}
          <DockItem className="aspect-square rounded-lg hover:bg-gray-100/10 dark:hover:bg-neutral-800/20 transition-colors relative">
            <DockLabel>Toggle Theme</DockLabel>
            <DockIcon>
              <div 
                className="w-full h-full flex items-center justify-center cursor-pointer"
                onClick={toggleTheme}
              >
                <div>
                  <div className="w-6 h-6">
                    {theme === 'light' ? (
                      <Moon className="w-full h-full text-gray-600 dark:text-gray-400" />
                    ) : (
                      <Sun className="w-full h-full text-gray-600 dark:text-gray-400" />
                    )}
                  </div>
                </div>
              </div>
            </DockIcon>
          </DockItem>

          {/* Logout Button - Only show when user is authenticated */}
          {user && (
            <DockItem className="aspect-square rounded-lg bg-rmz-primary hover:bg-rmz-primary/90 transition-colors">
              <DockLabel className="bg-rmz-primary border-none">Logout</DockLabel>
              <DockIcon>
                <div 
                  className="w-full h-full flex items-center justify-center cursor-pointer"
                  onClick={handleLogout}
                >
                  <div>
                    <div className="w-6 h-6">
                      <LogOut className="w-full h-full text-white" />
                    </div>
                  </div>
                </div>
              </DockIcon>
            </DockItem>
          )}
        </Dock>
      </div>
    </header>
  );
}
export const COLLECTIONS = {
  GENERATIONS: 'generations',
  UPSCALED: 'upscaled',
  TEMP_UPLOADS: 'temp-uploads',
  USERS: 'users',
  SHARED_GALLERY: 'shared-gallery',
  USER_STATS: 'user-stats',
  GLOBAL_STATS: 'global-stats',
  COST_TRACKING: 'cost-tracking',
  GLOBAL_COSTS: 'global-costs'
} as const;

export const STORAGE_PATHS = {
  USER_IMAGES: 'user-images',
  UPSCALED: 'upscaled',
  TEMP_UPLOADS: 'temp-uploads'
} as const;

// Batch processing configuration
export const BATCH_SIZE = 5;

// Collection schema versions for migrations
export const SCHEMA_VERSIONS = {
  GENERATIONS: 1,
  USERS: 1,
  SHARED_GALLERY: 1
} as const;

// Collection indexes for query optimization
export const INDEXES = {
  GENERATIONS: [
    ['userId', 'createdAt'],
    ['status', 'createdAt']
  ],
  SHARED_GALLERY: [
    ['userId', 'createdAt'],
    ['originalGenerationId', 'imageUrl']
  ]
} as const;
import React, { useState, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight, Palette, Building2, Users2, Briefcase, ShoppingBag, Building } from 'lucide-react';

// Move categories outside component to prevent recreation on each render
const categories = [
  {
    id: 1,
    title: "Designers",
    desc: "Transform your creative vision into reality",
    url: "https://images.unsplash.com/photo-1572044162444-ad60f128bdea",
    span: "md:col-span-2 md:row-span-2",
    icon: Palette
  },
  {
    id: 2,
    title: "Creative Agencies",
    desc: "Empower your creative workflow with AI",
    url: "https://images.unsplash.com/photo-1497366216548-37526070297c",
    span: "md:col-span-1 md:row-span-2",
    icon: Briefcase
  },
  {
    id: 3,
    title: "Creators",
    desc: "Unleash your creativity with AI tools",
    url: "https://images.unsplash.com/photo-1579783901586-d88db74b4fe4",
    span: "md:col-span-1 md:row-span-2",
    icon: Users2
  },
  {
    id: 4,
    title: "SMEs",
    desc: "Scale your business with AI-powered tools",
    url: "https://images.unsplash.com/photo-1552664730-d307ca884978",
    span: "md:col-span-1 md:row-span-2",
    icon: Briefcase
  },
  {
    id: 5,
    title: "Fashion",
    desc: "Create stunning fashion visuals with AI",
    url: "https://images.unsplash.com/photo-1558769132-cb1aea458c5e",
    span: "md:col-span-2 md:row-span-1",
    icon: ShoppingBag
  },
  {
    id: 6,
    title: "Enterprise", 
    desc: "Custom AI solutions for your business needs",
    url: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab",
    span: "md:col-span-1 md:row-span-2",
    icon: Building2
  },
  {
    id: 7,
    title: "Architects", 
    desc: "Design innovative spaces with AI visualization",
    url: "https://images.unsplash.com/photo-1487958449943-2429e8be8625",
    span: "md:col-span-2 md:row-span-1",
    icon: Building
  }
];

// Create a memoized category card component
const CategoryCard = memo(({ item, index, onClick }: {
  item: typeof categories[0],
  index: number,
  onClick: () => void
}) => (
  <motion.div
    layoutId={`media-${item.id}`}
    className={`relative overflow-hidden rounded-2xl cursor-pointer group transform hover:scale-[1.02] transition-all duration-300 h-full ${item.span}`}
    onClick={onClick}
    variants={{
      hidden: { y: 50, scale: 0.9, opacity: 0 },
      visible: {
        y: 0,
        scale: 1,
        opacity: 1,
        transition: {
          type: "spring",
          stiffness: 350,
          damping: 25,
          delay: index * 0.05
        }
      }
    }}
    whileHover={{ scale: 1.02 }}
  >
    <img
      src={item.url}
      alt={item.title}
      className="absolute inset-0 w-full h-full object-cover object-center transform group-hover:scale-105 transition-transform duration-300 z-10"
      loading="lazy"
    />
    <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-20">
      <div className="absolute bottom-0 left-0 right-0 p-6">
        <div className="flex items-center gap-2 mb-2">
          <item.icon className="w-5 h-5 text-[#00F5D4]" />
          <h3 className="text-white text-lg font-medium">
            {item.title}
          </h3>
        </div>
        <p className="text-white/70 text-sm mb-3">
          {item.desc}
        </p>
        <div className="flex items-center gap-2 text-[#00F5D4] text-sm font-medium">
          <span>Learn More</span>
          <ArrowRight className="w-4 h-4 transform transition-transform duration-300 group-hover:translate-x-1" />
        </div>
      </div>
    </div>
  </motion.div>
));

CategoryCard.displayName = 'CategoryCard';

export function ForWhoSection() {
  const navigate = useNavigate();

  // Memoize click handler
  const handleClick = useCallback(() => {
    navigate('/beta');
  }, [navigate]);

  return (
    <div className="py-32 px-4 overflow-hidden relative">
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto bg-white/5 backdrop-blur-sm border border-white/10 rounded-3xl p-8 relative overflow-hidden">
          {/* Background gradients */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-rmz-darker/50 to-transparent pointer-events-none" />
          
          {/* Section content */}
          <div className="relative z-10">
            <h2 className="text-3xl font-bold text-center mb-12 bg-gradient-to-r from-[#FF3B8B] via-[#B86EFF] to-[#00F5D4] text-transparent bg-clip-text">
              Who is RMZ.AI for?
            </h2>
            
            <motion.div 
              className="grid grid-cols-1 md:grid-cols-4 gap-6"
              style={{ gridAutoRows: 'minmax(250px, auto)' }}
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: { staggerChildren: 0.1 }
                }
              }}
            >
              {categories.map((item, index) => (
                <CategoryCard
                  key={item.id}
                  item={item}
                  index={index}
                  onClick={handleClick}
                />
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
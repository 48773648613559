import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { app } from './config';
import { STORAGE_PATHS } from './collections';
import { retry } from '../utils/retry'; 

const storage = getStorage(app);

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const MAX_CONCURRENT_UPLOADS = 4;
const UPLOAD_CHUNK_SIZE = 2 * 1024 * 1024;
const CACHE_CONTROL = 'public, max-age=31536000';
const CONTENT_DISPOSITION = 'inline'; // Ensure browser displays image instead of downloading

interface UploadOptions {
  contentType?: string;
  metadata?: Record<string, string>;
  concurrent?: boolean;
  retries?: number;
  chunkSize?: number;
}

// Memory cache for recently uploaded URLs
const urlCache = new Map<string, string>();

class Semaphore {
  private permits: number;
  private queue: Array<() => void> = [];
  private active = 0;

  constructor(permits: number) {
    this.permits = permits;
  }

  async acquire(): Promise<void> {
    if (this.active < this.permits) {
      this.active++;
      return Promise.resolve();
    }
    return new Promise<void>(resolve => {
      this.queue.push(resolve);
    });
  }

  release(): void {
    if (this.queue.length > 0 && this.active <= this.permits) {
      const next = this.queue.shift();
      next?.();
    } else {
      this.active--;
    }
  }
}

const uploadSemaphore = new Semaphore(MAX_CONCURRENT_UPLOADS);

// Optimized fetch with retry and caching
const fetchWithRetry = retry(async (url: string): Promise<Response> => {
  const response = await fetch(url, { 
    mode: 'cors',
    headers: { 'Accept': 'image/*' }
  });
  
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  
  return response;
}, {
  retries: MAX_RETRIES,
  delay: RETRY_DELAY,
  backoff: true
});

async function uploadToStorage(
  path: string,
  blob: Blob,
  options: UploadOptions = {}
): Promise<string> {
  // Check cache first
  const cacheKey = `${path}:${blob.size}`;
  const cachedUrl = urlCache.get(cacheKey);
  if (cachedUrl) return cachedUrl;

  if (!options.concurrent) {
    await uploadSemaphore.acquire();
  }

  try {
    const storageRef = ref(storage, path);
    
    // Optimized metadata
    const metadata = {
      contentType: options.contentType || blob.type || 'image/jpeg',
      customMetadata: options.metadata,
      cacheControl: CACHE_CONTROL,
      contentDisposition: CONTENT_DISPOSITION
    };

    // Parallel upload and URL generation
    const [snapshot, existingUrl] = await Promise.all([
      uploadBytes(storageRef, blob, metadata),
      getDownloadURL(storageRef).catch(() => null)
    ]);
    
    // Force cache invalidation by adding timestamp
    const downloadUrl = existingUrl || await getDownloadURL(snapshot.ref);
    const urlWithTimestamp = `${downloadUrl}?t=${Date.now()}`;

    if (!urlWithTimestamp) {
      throw new Error('Failed to get download URL after upload');
    }

    // Cache the URL
    urlCache.set(cacheKey, urlWithTimestamp);
    
    // Clean old cache entries
    if (urlCache.size > 1000) {
      const oldestKey = urlCache.keys().next().value;
      urlCache.delete(oldestKey);
    }

    return urlWithTimestamp;
  } finally {
    if (!options.concurrent) {
      uploadSemaphore.release();
    }
  }
}

export async function uploadGeneratedImage(
  userId: string,
  imageUrl: string,
  type: 'generation' | 'upscaled' | 'temp-uploads'
): Promise<string> {
  try {
    if (!userId || !imageUrl) {
      console.warn('Missing required parameters for image upload');
      return imageUrl;
    }

    // Download the image with retry mechanism
    const response = await fetchWithRetry(imageUrl);
    const blob = await response.blob();
    
    if (!blob.size) {
      console.warn('Retrieved empty image blob');
      return imageUrl;
    }

    // Create a unique filename with timestamp
    const timestamp = Date.now();
    const filename = `${timestamp}.jpg`;
    
    // Determine the storage path based on type
    let storagePath: string;
    switch (type) {
      case 'upscaled':
        storagePath = `${STORAGE_PATHS.USER_IMAGES}/${userId}/upscaled/${filename}`;
        break;
      case 'temp-uploads':
        storagePath = `${STORAGE_PATHS.USER_IMAGES}/${userId}/temp-uploads/${filename}`;
        break;
      default:
        storagePath = `${STORAGE_PATHS.USER_IMAGES}/${userId}/${filename}`;
    }

    // Upload with metadata
    const downloadUrl = await uploadToStorage(storagePath, blob, {
      contentType: blob.type || 'image/jpeg',
      metadata: {
        originalUrl: imageUrl,
        uploadedAt: timestamp.toString(),
        userId,
        type
      }
    });

    return downloadUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    // Return original URL as fallback
    return imageUrl;
  }
}

export async function deleteGeneratedImage(imageUrl: string): Promise<void> {
  try {
    if (!imageUrl) {
      console.warn('No image URL provided for deletion');
      return;
    }

    // Extract the storage path from the Firebase Storage URL
    const storagePathMatch = imageUrl.match(/o\/(.+?)\?/);
    if (!storagePathMatch) {
      console.warn('Could not extract storage path from URL:', imageUrl);
      return;
    }

    const storagePath = decodeURIComponent(storagePathMatch[1]);
    const imageRef = ref(storage, storagePath);
    
    try {
      await deleteObject(imageRef);
      console.log('Successfully deleted image from storage:', storagePath);
    } catch (error) {
      if ((error as any)?.code === 'storage/object-not-found') {
        console.warn('Image already deleted or not found:', storagePath);
      } else {
        throw error;
      }
    }
  } catch (error) {
    console.error('Error deleting image from storage:', error);
    throw new Error('Failed to delete image from storage');
  }
}
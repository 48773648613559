import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs,
  orderBy, 
  serverTimestamp,
  enableIndexedDbPersistence,
  deleteDoc,
  doc,
  updateDoc,
  Timestamp,
  DocumentData,
  runTransaction,
  getDoc,
  increment,
  onSnapshot
} from 'firebase/firestore';
import { db } from './config';
import { uploadGeneratedImage, deleteGeneratedImage } from './storage';
import type { TaskStatus, LoraModel, StyleOptions } from '../types';
import axios from 'axios';
import { COLLECTIONS } from './collections';

const WEBHOOK_URL = 'https://hook.eu2.make.com/5cktm3b6fq7jpuqh8zyywenmkmp17xcu';

async function notifyWebhook(data: any) {
  try {
    await axios.post(WEBHOOK_URL, data);
    console.log('Webhook notification sent successfully');
  } catch (error) {
    console.error('Failed to notify webhook:', error);
  }
}

// Initialize Firestore with persistence enabled
try {
  enableIndexedDbPersistence(db, {
    synchronizeTabs: true
  }).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence enabled in first tab only');
    } else if (err.code === 'unimplemented') {
      console.warn('Browser doesn\'t support persistence');
    }
  });
} catch (err) {
  console.warn('Error initializing persistence:', err);
}

// Type definitions
export interface TempUploadDocument {
  userId: string;
  imageUrl: string;
  createdAt: Timestamp;
}

export interface UpscaledDocument {
  userId: string;
  originalImageUrl: string;
  upscaledImageUrl: string;
  prompt?: string;
  createdAt: Timestamp;
  metadata?: {
    type: 'upscale';
    timestamp: number;
  };
}

export interface SharedGenerationDocument extends DocumentData {
  userId: string;
  originalGenerationId: string;
  prompt: string;
  imageUrl: string;
  createdAt: Timestamp;
  metadata: {
    type: string;
    originalImage?: string;
    timestamp: number;
    loraModels?: LoraModel[];
    styles?: StyleOptions;
    aspectRatio?: string;
  };
}

export interface UserStats {
  userId: string;
  totalGenerations: number;
  lastGeneratedAt: Timestamp;
}

export interface GlobalStats {
  totalGenerations: number;
  lastUpdatedAt: Timestamp;
}

export interface CostTracking {
  userId: string;
  totalCost: number;
  totalImages: number;
  lastUpdated: Timestamp;
}

export interface GlobalCosts {
  totalCost: number;
  totalImages: number;
  lastUpdated: Timestamp;
}

// Function implementations
export async function saveTempUpload(userId: string, imageDataUrl: string): Promise<string> {
  try {
    if (!userId) throw new Error('User ID is required');

    const firebaseUrl = await uploadGeneratedImage(userId, imageDataUrl, 'temp-uploads');

    const tempUploadsRef = collection(db, COLLECTIONS.TEMP_UPLOADS);
    await addDoc(tempUploadsRef, {
      userId,
      imageUrl: firebaseUrl,
      createdAt: serverTimestamp()
    });

    return firebaseUrl;
  } catch (error) {
    console.error('Error saving temporary upload:', error);
    throw error;
  }
}

export async function saveUpscaledImage(userId: string, data: Omit<UpscaledDocument, 'userId' | 'createdAt'>): Promise<void> {
  try {
    if (!userId) throw new Error('User ID is required');

    const upscaledRef = collection(db, COLLECTIONS.UPSCALED);
    await addDoc(upscaledRef, {
      userId,
      ...data,
      createdAt: serverTimestamp(),
      metadata: {
        type: 'upscale',
        timestamp: Date.now()
      }
    });
  } catch (error) {
    console.error('Error saving upscaled image:', error);
    throw error;
  }
}

export async function getUserGenerations(userId: string): Promise<TaskStatus[]> {
  try {
    if (!userId) {
      console.warn('No user ID provided for getUserGenerations');
      return [];
    }

    const generationsRef = collection(db, COLLECTIONS.GENERATIONS);
    const generationsQuery = query(
      generationsRef,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    const upscaledRef = collection(db, COLLECTIONS.UPSCALED);
    const upscaledQuery = query(
      upscaledRef,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    const [generationsSnapshot, upscaledSnapshot] = await Promise.all([
      getDocs(generationsQuery),
      getDocs(upscaledQuery)
    ]);

    const regularGenerations = generationsSnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        task_id: doc.id,
        status: 'COMPLETED',
        prompt: data.prompt || '',
        image_urls: data.firebaseImageUrls?.length ? data.firebaseImageUrls : data.imageUrls,
        completed_steps: data.completedSteps || 0,
        estimated_steps: data.estimatedSteps || 0,
        execution_time: data.executionTime,
        seed: data.seed,
        loraModels: data.loraModels,
        styles: data.styles,
        aspectRatio: data.aspectRatio,
        metadata: {
          type: 'generation',
          timestamp: data.metadata?.timestamp || data.createdAt?.toMillis() || Date.now()
        }
      };
    });

    const upscaledGenerations = upscaledSnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        task_id: doc.id,
        status: 'COMPLETED',
        prompt: data.prompt || 'Upscaled image',
        image_urls: [data.upscaledImageUrl],
        completed_steps: 100,
        estimated_steps: 100,
        metadata: {
          type: 'upscale',
          originalImage: data.originalImageUrl,
          timestamp: data.metadata?.timestamp || data.createdAt?.toMillis() || Date.now()
        }
      };
    });

    return [...regularGenerations, ...upscaledGenerations].sort((a, b) => {
      const aTime = a.metadata?.timestamp || 0;
      const bTime = b.metadata?.timestamp || 0;
      return bTime - aTime;
    });
  } catch (error) {
    console.error('Error loading generations:', error);
    return [];
  }
}

export async function saveGeneration(
  userId: string, 
  generation: TaskStatus,
  loraModels: LoraModel[] = [],
  styles: StyleOptions = {
    style1: 'No Style',
    style2: 'No Style',
    style3: 'No Style',
    style4: 'No Style'
  },
  aspectRatio: string = '1:1'
): Promise<void> {
  try {
    if (!userId) throw new Error('User ID is required');

    const firebaseImageUrls = generation.image_urls?.length ? 
      await Promise.all(
        generation.image_urls.map(url => 
          uploadGeneratedImage(userId, url, 'generation')
        )
      ) : [];

    // Calculate cost - $0.08 per image
    const imageCount = firebaseImageUrls.length;
    const costPerImage = 0.08;
    const totalCost = imageCount * costPerImage;

    // Run everything in a transaction to ensure data consistency
    await runTransaction(db, async (transaction) => {
      // First, perform all reads
      const userStatsRef = doc(db, 'user-stats', userId);
      const globalStatsRef = doc(db, 'global-stats', 'total');
      const userCostRef = doc(db, 'cost-tracking', userId);
      const globalCostRef = doc(db, 'global-costs', 'total');
      
      const [userStatsDoc, globalStatsDoc, userCostDoc, globalCostDoc] = await Promise.all([
        transaction.get(userStatsRef),
        transaction.get(globalStatsRef),
        transaction.get(userCostRef),
        transaction.get(globalCostRef)
      ]);

      // Save the generation
      const generationsRef = collection(db, COLLECTIONS.GENERATIONS);
      const newGenerationRef = doc(generationsRef);
      
      const generationData = {
        userId,
        status: generation.status,
        prompt: generation.prompt || '',
        imageUrls: generation.image_urls || [],
        firebaseImageUrls,
        completedSteps: generation.completed_steps || 0,
        estimatedSteps: generation.estimated_steps || 0,
        executionTime: generation.execution_time || null,
        seed: generation.seed || 0,
        createdAt: serverTimestamp(),
        loraModels,
        styles,
        aspectRatio,
        cost: totalCost,
        metadata: {
          type: 'generation',
          timestamp: Date.now()
        }
      };

      transaction.set(newGenerationRef, generationData);

      // Update user cost tracking
      if (!userCostDoc.exists()) {
        transaction.set(userCostRef, {
          userId,
          totalCost,
          totalImages: imageCount,
          lastUpdated: serverTimestamp()
        });
      } else {
        transaction.update(userCostRef, {
          totalCost: increment(totalCost),
          totalImages: increment(imageCount),
          lastUpdated: serverTimestamp()
        });
      }

      // Update global cost tracking
      if (!globalCostDoc.exists()) {
        transaction.set(globalCostRef, {
          totalCost,
          totalImages: imageCount,
          lastUpdated: serverTimestamp()
        });
      } else {
        transaction.update(globalCostRef, {
          totalCost: increment(totalCost),
          totalImages: increment(imageCount),
          lastUpdated: serverTimestamp()
        });
      }

      // Update existing stats
      if (!userStatsDoc.exists()) {
        transaction.set(userStatsRef, {
          userId,
          totalGenerations: imageCount,
          lastGeneratedAt: serverTimestamp()
        });
      } else {
        transaction.update(userStatsRef, {
          totalGenerations: increment(imageCount),
          lastGeneratedAt: serverTimestamp()
        });
      }

      if (!globalStatsDoc.exists()) {
        transaction.set(globalStatsRef, {
          totalGenerations: imageCount,
          lastUpdatedAt: serverTimestamp()
        });
      } else {
        transaction.update(globalStatsRef, {
          totalGenerations: increment(imageCount),
          lastUpdatedAt: serverTimestamp()
        });
      }
    });
  } catch (error) {
    console.error('Error saving generation:', error);
    throw error;
  }
}

export async function deleteGeneration(generationId: string, imageUrls: string[]): Promise<void> {
  try {
    // First, try to get the generation document
    const generationRef = doc(db, COLLECTIONS.GENERATIONS, generationId);
    const generationSnap = await getDoc(generationRef);

    // If it's not a regular generation, try upscaled collection
    if (!generationSnap.exists()) {
      const upscaledRef = doc(db, COLLECTIONS.UPSCALED, generationId);
      const upscaledSnap = await getDoc(upscaledRef);

      if (upscaledSnap.exists()) {
        // Delete upscaled document and its image
        await deleteDoc(upscaledRef);
        await Promise.all(imageUrls.map(url => deleteGeneratedImage(url)));
        return;
      }

      throw new Error('Generation not found');
    }

    // For regular generations, delete shared gallery entries first
    const sharedRef = collection(db, COLLECTIONS.SHARED_GALLERY);
    const sharedQuery = query(
      sharedRef,
      where('originalGenerationId', '==', generationId)
    );
    const sharedDocs = await getDocs(sharedQuery);

    // Delete all related documents and images in parallel
    await Promise.all([
      deleteDoc(generationRef),
      ...sharedDocs.docs.map(doc => deleteDoc(doc.ref)),
      ...imageUrls.map(url => deleteGeneratedImage(url))
    ]);
  } catch (error) {
    console.error('Error deleting generation:', error);
    throw new Error('Failed to delete generation');
  }
}

export async function deleteGenerationImage(
  generationId: string, 
  imageUrl: string, 
  allImageUrls: string[]
): Promise<void> {
  try {
    // First, try to get the generation document
    const generationRef = doc(db, COLLECTIONS.GENERATIONS, generationId);
    const generationSnap = await getDoc(generationRef);

    // If it's not a regular generation, try upscaled collection
    if (!generationSnap.exists()) {
      const upscaledRef = doc(db, COLLECTIONS.UPSCALED, generationId);
      const upscaledSnap = await getDoc(upscaledRef);

      if (upscaledSnap.exists()) {
        // For upscaled images, delete the entire document
        await deleteDoc(upscaledRef);
        await deleteGeneratedImage(imageUrl);
        return;
      }

      throw new Error('Generation not found');
    }

    // For regular generations with a single image, delete the entire generation
    if (allImageUrls.length === 1) {
      await deleteGeneration(generationId, [imageUrl]);
      return;
    }

    // For regular generations with multiple images
    const generationData = generationSnap.data();
    const updatedImageUrls = (generationData.imageUrls || []).filter(url => url !== imageUrl);
    const updatedFirebaseImageUrls = (generationData.firebaseImageUrls || []).filter(url => url !== imageUrl);

    // Delete shared gallery entries for this specific image
    const sharedRef = collection(db, COLLECTIONS.SHARED_GALLERY);
    const sharedQuery = query(
      sharedRef,
      where('originalGenerationId', '==', generationId),
      where('imageUrl', '==', imageUrl)
    );
    const sharedDocs = await getDocs(sharedQuery);

    // Perform all deletions in parallel
    await Promise.all([
      updateDoc(generationRef, {
        imageUrls: updatedImageUrls,
        firebaseImageUrls: updatedFirebaseImageUrls
      }),
      ...sharedDocs.docs.map(doc => deleteDoc(doc.ref)),
      deleteGeneratedImage(imageUrl)
    ]);
  } catch (error) {
    console.error('Error deleting generation image:', error);
    throw new Error('Failed to delete image');
  }
}

export async function shareToGallery(userId: string, generation: TaskStatus, imageUrl: string): Promise<void> {
  try {
    if (!userId?.trim()) throw new Error('User ID is required');
    if (!imageUrl?.trim()) throw new Error('Image URL is required');
    if (!generation?.task_id?.trim()) throw new Error('Generation ID is required');

    await runTransaction(db, async (transaction) => {
      const sharedRef = collection(db, COLLECTIONS.SHARED_GALLERY);
      const existingQuery = query(
        sharedRef,
        where('userId', '==', userId),
        where('originalGenerationId', '==', generation.task_id),
        where('imageUrl', '==', imageUrl)
      );
      
      const existingDocs = await getDocs(existingQuery);
      if (!existingDocs.empty) {
        throw new Error('This image has already been shared to the community gallery');
      }

      const metadata = {
        type: generation.metadata?.type || 'generation',
        timestamp: Date.now(),
        ...(generation.loraModels?.length ? { loraModels: generation.loraModels } : {}),
        ...(generation.styles ? { styles: generation.styles } : {}),
        ...(generation.aspectRatio ? { aspectRatio: generation.aspectRatio } : {})
      };

      const sharedData = {
        userId: userId.trim(),
        originalGenerationId: generation.task_id.trim(),
        prompt: (generation.prompt || '').slice(0, 1000),
        imageUrl: imageUrl.trim(),
        metadata,
        createdAt: serverTimestamp()
      };

      const newSharedRef = doc(sharedRef);
      transaction.set(newSharedRef, sharedData);
    });
  } catch (error) {
    console.error('Error sharing to gallery:', error);
    if (error instanceof Error && error.message.includes('already shared')) {
      throw error;
    }
    throw new Error('Failed to share to community gallery');
  }
}

export async function unshareFromGallery(sharedId: string): Promise<void> {
  try {
    await runTransaction(db, async (transaction) => {
      const sharedDocRef = doc(db, COLLECTIONS.SHARED_GALLERY, sharedId);
      const sharedDoc = await transaction.get(sharedDocRef);
      
      if (!sharedDoc.exists()) {
        throw new Error('Shared image not found');
      }

      transaction.delete(sharedDocRef);
    });
  } catch (error) {
    console.error('Error unsharing from gallery:', error);
    throw new Error('Failed to unshare from community gallery');
  }
}

export async function isImageShared(userId: string, generationId: string, imageUrl: string): Promise<string | null> {
  try {
    if (!userId || !generationId || !imageUrl) {
      console.warn('Missing parameters for isImageShared check');
      return null;
    }

    const sharedRef = collection(db, COLLECTIONS.SHARED_GALLERY);
    const q = query(
      sharedRef,
      where('userId', '==', userId),
      where('originalGenerationId', '==', generationId),
      where('imageUrl', '==', imageUrl)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.empty ? null : snapshot.docs[0].id;
  } catch (error) {
    console.error('Error checking shared status:', error);
    return null;
  }
}

export async function approveBetaApplication(applicationId: string): Promise<void> {
  try {
    const applicationRef = doc(db, 'beta-contacts', applicationId);
    const applicationSnap = await getDoc(applicationRef);

    if (!applicationSnap.exists()) {
      throw new Error('Application not found');
    }

    const data = applicationSnap.data();
    if (!data.email || !data.accessCode) {
      throw new Error('Invalid application data');
    }

    // Update application status
    await updateDoc(applicationRef, {
      isApproved: true,
      status: 'approved',
      approvedAt: serverTimestamp()
    });

    return;
  } catch (error) {
    console.error('Error approving beta application:', error);
    throw new Error('Failed to approve beta application');
  }
}

export async function handleBetaApproval(applicationId: string): Promise<void> {
  try {
    const applicationRef = doc(db, 'beta-contacts', applicationId);
    const applicationSnap = await getDoc(applicationRef);

    if (!applicationSnap.exists()) {
      throw new Error('Application not found');
    }

    const data = applicationSnap.data();
    if (!data.email || !data.accessCode) {
      throw new Error('Invalid application data');
    }

    // Update application status
    await updateDoc(applicationRef, {
      isApproved: true,
      status: 'approved',
      approvedAt: serverTimestamp()
    });

    // Mark email as sent
    await updateDoc(applicationRef, {
      emailSent: true,
      emailSentAt: serverTimestamp()
    });

    return;
  } catch (error) {
    console.error('Error approving beta application:', error);
    throw new Error('Failed to approve beta application');
  }
}

// Set up listener for beta application changes
export function listenToBetaApplication(applicationId: string): () => void {
  const applicationRef = doc(db, 'beta-contacts', applicationId);
  
  const unsubscribe = onSnapshot(applicationRef, async (snapshot) => {
    if (!snapshot.exists()) return;
    
    const data = snapshot.data();
    
    // Check if application was just approved
    if (data.isApproved && !data.emailSent && data.accessCode) {
      try {
        // Notify webhook first
        await notifyWebhook({
          type: 'beta_approval',
          email: data.email,
          accessCode: data.accessCode,
          instagramUsername: data.instagramUsername,
          timestamp: Date.now()
        });

        // Mark email as sent
        await updateDoc(applicationRef, {
          emailSent: true,
          emailSentAt: serverTimestamp()
        });
      } catch (error) {
        console.error('Failed to process approval:', error);
      }
    }
  });

  return unsubscribe;
}